import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '12px'
  }
}));

export default function ScheduleForm({
  isEdit,
  serial,
  isLoading,
  scheduleSettings,
  writeScheduleSettings,
  writeSettingsResult,
  switchDisplay
}) {

  const classes = useStyles();

  const [inputSettings, setInputSettings] = useState({
    id: '',
    serial: serial,
    block: '1',
    duration: '1',
    medium: 'Water',
    schedule: '2021-01-31',
    session: '1',
    spec: '1'
  });

  const [isDisplaySpec, setIsDisplaySpec] = useState(false);

  const PARAM = {
    block: 'block',
    duration: 'duration',
    medium: 'medium',
    schedule: 'schedule',
    session: 'session',
    spec: 'spec'
  };

  useEffect(() => {
    if (scheduleSettings && isEdit) {
      setInputSettings({
        id: scheduleSettings.id.toString(),
        serial: scheduleSettings.serial,
        block: scheduleSettings.block,
        duration: scheduleSettings.duration,
        medium: scheduleSettings.medium,
        schedule: scheduleSettings.schedule,
        session: scheduleSettings.session,
        spec: scheduleSettings.spec
      });

      if (scheduleSettings.medium === "Fertilizer") {
        setIsDisplaySpec(true);
      } else {
        setIsDisplaySpec(false);
      }
    }
  }, [scheduleSettings]);

  const handleClickSubmitButton = () => {

    const postPayload = {
      schedule: [
        {
          id: inputSettings.id,
          serial: inputSettings.serial,
          block: inputSettings.block,
          duration: inputSettings.duration,
          medium: inputSettings.medium,
          schedule: inputSettings.schedule,
          session: inputSettings.session,
          spec: inputSettings.spec,
        }
      ]
    };

    const putPayload = {
      id: inputSettings.id,
      serial: inputSettings.serial,
      block: inputSettings.block,
      duration: inputSettings.duration,
      medium: inputSettings.medium,
      schedule: inputSettings.schedule,
      session: inputSettings.session,
      spec: inputSettings.spec,
    };

    const method = isEdit ? 'PUT' : 'POST';
    const payload = isEdit ? putPayload : postPayload;

    writeScheduleSettings(payload, method);
  };

  const handleChange = (event, param) => {
    let value = event.target.value;

    if (param === PARAM.medium) {
      if (value === "Fertilizer") {
        setIsDisplaySpec(true);
      } else {
        setIsDisplaySpec(false);
      }
    }

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  const ecInputProps = {
    min: 0,
    max: 9,
    step: 0.01
  };

  const durationInputProps = {
    min: 0,
    max: 60,
    step: 1
  };


  return (
    <Card>
      <CardContent>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => switchDisplay('table')}
            classes={{ root: classes.button }}
          >
            BACK
          </Button>
        </Box>
        <form noValidate autoComplete="off">
          <Typography variant='h5' align='center' >
            {isEdit ? 'Edit Schedule' : 'Add New Schedule'}
          </Typography>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="label">Schedule</FormLabel>
            <Box display='flex' alignItems='center'>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="dd-mm-yyyy"
                type="date"
                value={inputSettings.schedule}
                onChange={(event) => handleChange(event, PARAM.schedule)}
              />
            </Box>
          </FormControl>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="legend">SESSION</FormLabel>
            <RadioGroup
              value={inputSettings.session}
              onChange={(event) => handleChange(event, PARAM.session)}
            >
              <FormControlLabel value={"1"} control={<Radio color="primary" />} label="1" />
              <FormControlLabel value={"2"} control={<Radio color="primary" />} label="2" />
              <FormControlLabel value={"3"} control={<Radio color="primary" />} label="3" />
              <FormControlLabel value={"4"} control={<Radio color="primary" />} label="4" />
              <FormControlLabel value={"5"} control={<Radio color="primary" />} label="5" />
              <FormControlLabel value={"6"} control={<Radio color="primary" />} label="6" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="legend">BLOCK</FormLabel>
            <RadioGroup
              value={inputSettings.block}
              onChange={(event) => handleChange(event, PARAM.block)}
            >
              <FormControlLabel value={"1"} control={<Radio color="primary" />} label="1" />
              <FormControlLabel value={"2"} control={<Radio color="primary" />} label="2" />
              <FormControlLabel value={"3"} control={<Radio color="primary" />} label="3" />
              <FormControlLabel value={"4"} control={<Radio color="primary" />} label="4" />
              <FormControlLabel value={"5"} control={<Radio color="primary" />} label="5" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="legend">TYPE</FormLabel>
            <RadioGroup
              value={inputSettings.medium}
              onChange={(event) => handleChange(event, PARAM.medium)}
            >
              <FormControlLabel value={'Water'} control={<Radio color="primary" />} label="WATER" />
              <FormControlLabel value={'Fertilizer'} control={<Radio color="primary" />} label="FERTILIZER" />
              <FormControlLabel value={'None'} control={<Radio color="primary" />} label="NONE" />
            </RadioGroup>
          </FormControl>
          {isDisplaySpec &&
            <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
              <FormLabel component="label">EC</FormLabel>
              <Box display='flex' alignItems='center'>
                <TextField
                  id="outlined-basic"
                  label="ms/cm"
                  variant="outlined"
                  type="number"
                  size='small'
                  inputProps={ecInputProps}
                  value={inputSettings.spec}
                  onChange={(event) => handleChange(event, PARAM.spec)}
                />
              </Box>
            </FormControl>
          }
          <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
            <FormLabel component="label">DURATION</FormLabel>
            <Box display='flex' alignItems='center'>
              <TextField
                id="outlined-basic"
                label="min"
                variant="outlined"
                type="number"
                size='small'
                inputProps={durationInputProps}
                value={inputSettings.duration}
                onChange={(event) => handleChange(event, PARAM.duration)}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </FormControl>
          <Box m={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSubmitButton}
              disabled={isLoading}
              classes={{ root: classes.button }}
            >
              SUBMIT {isLoading && <CircularProgress size={12} />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => switchDisplay('table')}
              disabled={isLoading}
              classes={{ root: classes.button }}
            >
              BACK
            </Button>
          </Box>
        </form>
        <AlertSnackbar writeSettingsResult={writeSettingsResult} />
      </CardContent>
    </Card >
  );
}
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 8,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '0 12px'
  },
  explanationLabel: {
    fontSize: 14,
    textAlign: 'center'
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 2
  },
  divider: {
    width: '100%'
  }
}));

export default function PidSetting({
  houseId,
  pidp,
  pidi,
  pidd,
  method,
  handleInputChange
}) {
  const classes = useStyles();

  const pidInputProps = {
    min: 0,
    max: 100,
    step: 1
  };

  const [isPidShown, setIsPidShown] = useState(false);

  useEffect(() => {
    if (method === 0 || method === 3) {
      setIsPidShown(false);
    } else {
      setIsPidShown(true);
    }
  }, [method]);

  const handleMethodChange = (event) => {
    if (event.target.value === '0' || event.target.value === '3') {
      setIsPidShown(false);
    } else {
      setIsPidShown(true);
    }
  };

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">CONTROL TYPE</FormLabel>
        <RadioGroup
          value={method}
          onChange={(event) => {
            handleMethodChange(event);
            handleInputChange(event, `method${houseId}`);
          }
          }
        >
          <FormControlLabel value={0} control={<Radio color="primary" />} label="ON OFF" />
          <FormControlLabel value={3} control={<Radio color="primary" />} label="SELF-TUNING" />
          <FormControlLabel value={1} control={<Radio color="primary" />} label="PID" />
        </RadioGroup>
      </FormControl>
      <Collapse in={isPidShown}>
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label" classes={{ root: classes.explanationLabel }}>
            PROPORTIONAL
          </FormLabel>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size='small'
              inputProps={pidInputProps}
              value={pidp}
              onChange={(event) => handleInputChange(event, `pidp${houseId}`)}
            />
          </Box>
        </FormControl>
        <Divider classes={{ root: classes.divider }} />
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label" classes={{ root: classes.explanationLabel }}>
            INTEGRAL
          </FormLabel>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size='small'
              inputProps={pidInputProps}
              value={pidi}
              onChange={(event) => handleInputChange(event, `pidi${houseId}`)}
            />
          </Box>
        </FormControl>
        <Divider classes={{ root: classes.divider }} />
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label" classes={{ root: classes.explanationLabel }}>
            DERIVATIVES
          </FormLabel>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              size='small'
              inputProps={pidInputProps}
              value={pidd}
              onChange={(event) => handleInputChange(event, `pidd${houseId}`)}
            />
          </Box>
        </FormControl>
      </Collapse>
    </Box >
  );
}

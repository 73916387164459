import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { utcToMtyConvertionDate } from "../shared/DateTimeConvertion";
import { refetchApi } from '../shared/utils';
am4core.useTheme(am4themes_animated);

const moment = require("moment-timezone");

var total_properties = [];

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	return [year, month, day].join("-");
}

function RetrieveChart() {
	const { search } = useLocation();
	const values = queryString.parse(search);

	let epochStartTime = moment(
		formatDate(
			new Date(new Date().setHours(new Date().getHours() - 10)).getTime()
		)
	)
		.tz("Asia/Kuala_Lumpur")
		.unix();

	let epochEndTime = moment(formatDate(new Date()))
		.tz("Asia/Kuala_Lumpur")
		.unix();

	const [fullChartData, setFullChartData] = useState();
	const [datetimeChange, setDatetimeChange] = useState(false);
	const [buttonIsActive, setButtonIsActive] = useState(false);

	function datetimeHandleChange(event) {
		setDatetimeChange(true);
	}

	let default_start_time = String(
		moment(new Date(new Date().setHours(new Date().getHours() - 10)))
			.tz("Asia/Kuala_Lumpur")
			.format("HH:mm")
	);
	let default_end_time = String(
		moment().tz("Asia/Kuala_Lumpur").format("HH:mm")
	);

	// Retrieve Data
	useEffect(() => {
		// Set Default Date
		document.getElementById("start-time").value = default_start_time;
		document.getElementById("end-time").value = default_end_time;
		document.getElementById("end-date").valueAsDate = new Date();
		document.getElementById("start-date").valueAsDate = new Date(
			new Date().setHours(new Date().getHours() - 10)
		);

		fetch(
			`${process.env.REACT_APP_API_URL}/retrieveAllChart/${values.device_id}/${epochStartTime}/${epochEndTime}/`,
			{
				headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
			}
		)
			.then((res) => res.json())
			.then(
				(result) => {
					if (result.code && result.code === 'token_not_valid') {
						refetchApi();
					}

					setFullChartData(result);
				},

				(error) => {
					console.log(error);
				}
			);
	}, []);

	function generateChart() {
		if (datetimeChange) {
			//Retrieve data from input box
			let new_start_epochtime = moment(
				new Date(
					document.getElementById("start-date").value +
					" " +
					document.getElementById("start-time").value
				)
			)
				.tz("Asia/Kuala_Lumpur")
				.unix();
			let new_end_epochtime = moment(
				new Date(
					document.getElementById("end-date").value +
					" " +
					document.getElementById("end-time").value
				)
			)
				.tz("Asia/Kuala_Lumpur")
				.unix();

			fetch(
				`${process.env.REACT_APP_API_URL
				}/retrieveAllChart/${values.device_id}/${new_start_epochtime}/${new_end_epochtime}/`,
				{
					headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
				}
			)
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code && result.code === 'token_not_valid') {
							refetchApi();
						}

						setFullChartData(result);
						// Create chart instance
						let chart = am4core.create("chartdiv", am4charts.XYChart);
						chart.numberFormatter.numberFormat = "#.00";

						// Increase contrast by taking evey second color
						chart.colors.step = 2;

						let series;

						// Create axes
						let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
						dateAxis.renderer.minGridDistance = 50;

						// Create series
						function createAxisAndSeries(field, name, opposite, bullet) {
							let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
							if (chart.yAxes.indexOf(valueAxis) != 0) {
								valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
							}

							series = chart.series.push(new am4charts.LineSeries());
							series.dataFields.valueY = field;
							series.dataFields.dateX = "date";
							series.strokeWidth = 2;
							series.yAxis = valueAxis;
							series.name = name;
							series.tooltipText = "{name}: [bold]{valueY}[/]";
							series.tensionX = 0.8;
							series.showOnInit = true;

							valueAxis.renderer.line.strokeOpacity = 1;
							valueAxis.renderer.line.strokeWidth = 2;
							valueAxis.renderer.line.stroke = series.stroke;
							valueAxis.renderer.labels.template.fill = series.stroke;
							valueAxis.renderer.opposite = opposite;

							valueAxis.renderer.labels.template.fontSize = 10;
						}

						let field = [];
						let chartData = [];

						// Generate Axis
						for (let index = 0; index < total_properties.length; index++) {
							if (document.getElementById("check" + index).checked) {
								field.push(document.getElementById("check" + index).value);
								createAxisAndSeries(
									total_properties[index]["parameter"],
									total_properties[index]["label"],
									index % 2 == 0 ? false : true,
									"circle"
								);
							}
						}

						for (let index = 0; index < result["data"].length; index++) {
							let d = {
								date: new Date(
									String(
										moment(new Date(result["data"][index]["datetime"]))
											.tz("UTC")
											.format("YYYY-MM-DD[T]HH:mm:ss[Z]")
									)
								),
							};

							for (let m = 0; m < field.length; m++) {
								d[field[m]] = parseFloat(result["data"][index][field[m]]);
							}
							chartData.push(d);
						}

						//Add Data
						chart.data = chartData;

						// Add legend
						chart.legend = new am4charts.Legend();

						// Add cursor
						chart.cursor = new am4charts.XYCursor();

						// Scrollbar
						var scrollbar = new am4charts.XYChartScrollbar();
						scrollbar.series.push(series);
						chart.scrollbarX = scrollbar;
						chart.scrollbarX.parent = chart.bottomAxesContainer;
					},

					(error) => {
						console.log(error);
					}
				);
		} else {
			// Create chart instance
			let chart = am4core.create("chartdiv", am4charts.XYChart);
			chart.numberFormatter.numberFormat = "#.00";

			// Increase contrast by taking evey second color
			chart.colors.step = 2;

			let series;

			// Create axes
			let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
			dateAxis.renderer.minGridDistance = 50;

			// Create series
			function createAxisAndSeries(field, name, opposite, bullet) {
				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				if (chart.yAxes.indexOf(valueAxis) != 0) {
					valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
				}

				series = chart.series.push(new am4charts.LineSeries());
				series.dataFields.valueY = field;
				series.dataFields.dateX = "date";
				series.strokeWidth = 2;
				series.yAxis = valueAxis;
				series.name = name;
				series.tooltipText = "{name}: [bold]{valueY}[/]";
				series.tensionX = 0.8;
				series.showOnInit = true;

				valueAxis.renderer.line.strokeOpacity = 1;
				valueAxis.renderer.line.strokeWidth = 2;
				valueAxis.renderer.line.stroke = series.stroke;
				valueAxis.renderer.labels.template.fill = series.stroke;
				valueAxis.renderer.opposite = opposite;

				valueAxis.renderer.labels.template.fontSize = 10;
			}
			let field = [];
			let chartData = [];

			// Generate Axis
			for (let index = 0; index < total_properties.length; index++) {
				if (document.getElementById("check" + index).checked) {
					field.push(document.getElementById("check" + index).value);
					createAxisAndSeries(
						total_properties[index]["parameter"],
						total_properties[index]["label"],
						index % 2 == 0 ? false : true,
						"circle"
					);
				}
			}

			for (let index = 0; index < fullChartData["data"].length; index++) {
				let d = {
					date: utcToMtyConvertionDate(fullChartData["data"][index]["datetime"]),
				};

				for (let m = 0; m < field.length; m++) {
					d[field[m]] = parseFloat(fullChartData["data"][index][field[m]]);
				}
				chartData.push(d);
			}

			//Add Data
			chart.data = chartData;

			// Add legend
			chart.legend = new am4charts.Legend();

			// Add cursor
			chart.cursor = new am4charts.XYCursor();

			// Scrollbar
			var scrollbar = new am4charts.XYChartScrollbar();
			scrollbar.series.push(series);
			chart.scrollbarX = scrollbar;
			chart.scrollbarX.parent = chart.bottomAxesContainer;

			// Export
			chart.exporting.menu = new am4core.ExportMenu();
			chart.exporting.menu.align = "right";
			chart.exporting.menu.verticalAlign = "top";

			// Title
			let title = chart.titles.create();
			title.text = "Chart Summary";
			title.fontSize = 25;
			title.marginBottom = 30;
		}
	}

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL
			}/getChartParameter/${values.device_id}/`,
			{
				method: "GET",
				headers: {
					Authorization: "Token 62990ac3b609e5601a678c1e133416e6da7f10db",
				},
			}
		)
			.then((res) => res.json())
			.then(
				(result) => {
					for (let index = 0; index < result["chart_prop"].length; index++) {
						let label = result["chart_prop"][index]["label"];
						let parameter = result["chart_prop"][index]["parameter"];

						let d = {
							label: label,
							parameter: parameter,
						};

						total_properties.push(d);

						//Append Html
						$("#category").append(
							`<div className="col-6"><div className="form-check" style="margin-right: 8px">` +
							`<input className="form-check-input" type="checkbox" id="check${index}" value="${parameter}">` +
							`<label className="form-check-label">${label}</label>` +
							`</div></div>`
						);
					}
					setButtonIsActive(true);
				},
				(error) => {
					console.log(error);
				}
			);
	}, []);

	return (
		<div>
			<center>
				<div style={{ maxWidth: 1200 }}>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>Start Date</Label>
								<Input
									type="date"
									id="start-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Start Time</Label>
								<Input
									type="time"
									id="start-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{ margin: '0' }}>
						<Col>
							<FormGroup>
								<Label>End Date</Label>
								<Input
									type="date"
									id="end-date"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>End Time</Label>
								<Input
									type="time"
									id="end-time"
									onChange={(e) => {
										datetimeHandleChange(e);
									}}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className="row pr-0 py-2 pl-2 mx-0" id="category"></div>
					<br />
					{buttonIsActive ? (
						<Button color="primary" onClick={() => generateChart()}>
							Generate
						</Button>
					) : (
						<Button color="primary" disabled onClick={() => generateChart()}>
							Generate
						</Button>
					)}
					<div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
				</div>
			</center>
		</div>
	);
}

export default RetrieveChart;

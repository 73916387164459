import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';
import OverrideSetting from './OverrideSetting';
import StatusCard from './StatusCard';
import TimeSetting from './TimeSetting';
import AlertTimeSettings from './AlertTimeSettings';
import Collapse from '@material-ui/core/Collapse';
import ListIcon from '@material-ui/icons/List';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '0 12px'
  }
}));

export default function SessionsTimeSetting({
  mqttClient,
  scheduleSettings,
  readScheduleSettings,
  writeScheduleSettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult
}) {

  const classes = useStyles();

  const SESSION_IDS = ['a', 'b', 'c', 'd', 'e', 'f'];

  const [isConnected, setIsConnected] = useState(false);
  const [isDisplayAllSessions, setIsDisplayAllSessions] = useState(false);
  const [displayedSessionId, setDisplayedSessionId] = useState('a');

  useEffect(() => {
    if (mqttClient) {
      setIsConnected(true);
      const payload = {
        messageId: "rdsd",
        devices: "schedule",
        mode: 1
      };
      readScheduleSettings(payload);
    } else {
      setIsConnected(false);
    }
  }, [mqttClient]);

  const [inputSettings, setInputSettings] = useState({
    sc_h_a: '',
    sc_m_a: '',
    io_sc_a: '',
    sc_h_b: '',
    sc_m_b: '',
    io_sc_b: '',
    sc_h_c: '',
    sc_m_c: '',
    io_sc_c: '',
    sc_h_d: '',
    sc_m_d: '',
    io_sc_d: '',
    sc_h_e: '',
    sc_m_e: '',
    io_sc_e: '',
    sc_h_f: '',
    sc_m_f: '',
    io_sc_f: '',
    spwet: '',
    iospwet: '',
    sppressure: '',
  });

  useEffect(() => {
    if (scheduleSettings) {
      const deviceData = scheduleSettings.deviceData;

      setInputSettings({
        sc_h_a: deviceData.sc_h_a,
        sc_m_a: deviceData.sc_m_a,
        io_sc_a: deviceData.io_sc_a,
        sc_h_b: deviceData.sc_h_b,
        sc_m_b: deviceData.sc_m_b,
        io_sc_b: deviceData.io_sc_b,
        sc_h_c: deviceData.sc_h_c,
        sc_m_c: deviceData.sc_m_c,
        io_sc_c: deviceData.io_sc_c,
        sc_h_d: deviceData.sc_h_d,
        sc_m_d: deviceData.sc_m_d,
        io_sc_d: deviceData.io_sc_d,
        sc_h_e: deviceData.sc_h_e,
        sc_m_e: deviceData.sc_m_e,
        io_sc_e: deviceData.io_sc_e,
        sc_h_f: deviceData.sc_h_f,
        sc_m_f: deviceData.sc_m_f,
        io_sc_f: deviceData.io_sc_f,
        spwet: deviceData.spwet,
        iospwet: deviceData.iospwet,
        sppressure: deviceData.sppressure
      });
    }
  }, [scheduleSettings]);

  const handleClickSendButton = () => {

    const payload = {
      messageId: "wrsd",
      devices: [{
        deviceId: "schedule",
        deviceData: {
          sc_h_a: inputSettings.sc_h_a,
          sc_m_a: inputSettings.sc_m_a,
          io_sc_a: inputSettings.io_sc_a,
          sc_h_b: inputSettings.sc_h_b,
          sc_m_b: inputSettings.sc_m_b,
          io_sc_b: inputSettings.io_sc_b,
          sc_h_c: inputSettings.sc_h_c,
          sc_m_c: inputSettings.sc_m_c,
          io_sc_c: inputSettings.io_sc_c,
          sc_h_d: inputSettings.sc_h_d,
          sc_m_d: inputSettings.sc_m_d,
          io_sc_d: inputSettings.io_sc_d,
          sc_h_e: inputSettings.sc_h_e,
          sc_m_e: inputSettings.sc_m_e,
          io_sc_e: inputSettings.io_sc_e,
          sc_h_f: inputSettings.sc_h_f,
          sc_m_f: inputSettings.sc_m_f,
          io_sc_f: inputSettings.io_sc_f,
          spwet: inputSettings.spwet,
          iospwet: inputSettings.iospwet,
          sppressure: inputSettings.sppressure
        }
      }]
    };
    writeScheduleSettings(payload);
  };

  const handleClickRefreshButton = () => {
    const payload = {
      messageId: "rdsd",
      devices: "schedule",
      mode: 1
    };
    readScheduleSettings(payload);
  };

  const handleInputChange = (event, param) => {
    let value = parseFloat(event.target.value);

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  const [isAlertTimeSettingsOpen, setIsAlertTimeSettingsOpen] = useState(false);

  const handleCloseAlertTimeSettings = () => {
    setIsAlertTimeSettingsOpen(false);
    handleClickRefreshButton();
  };

  useEffect(() => {
    if (writeSettingsResult && writeSettingsResult === 'success')
      setIsAlertTimeSettingsOpen(true);
    return () => {
      setIsAlertTimeSettingsOpen(false);
    };
  }, [writeSettingsResult]);

  const handleClickArrow = (direction) => {
    const displayedSessionIndex = SESSION_IDS.indexOf(displayedSessionId);
    let nextSessionIndex;
    const isFirstSession = displayedSessionIndex === 0;
    const isLastSession = displayedSessionIndex === SESSION_IDS.length - 1;

    const firstSessionIndex = 0;
    const lastSessionIndex = SESSION_IDS.length - 1;

    if (direction === 'left') {
      if (isFirstSession) {
        nextSessionIndex = lastSessionIndex;
      } else {
        nextSessionIndex = displayedSessionIndex - 1;
      }
    } else {
      if (isLastSession) {
        nextSessionIndex = firstSessionIndex;
      } else {
        nextSessionIndex = displayedSessionIndex + 1;
      }
    }

    setDisplayedSessionId(SESSION_IDS[nextSessionIndex]);
  };

  return (
    <section className={classes.root}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <StatusCard isConnected={isConnected} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
            <CardContent>
              {!isDisplayAllSessions &&
                <Box display='flex' justifyContent='space-between'>
                  <IconButton onClick={() => handleClickArrow('left')} disabled={isDisplayAllSessions}>
                    <KeyboardArrowLeftIcon fontSize="large" />
                  </IconButton>
                  <IconButton onClick={() => handleClickArrow('right')} disabled={isDisplayAllSessions}>
                    <KeyboardArrowRightIcon fontSize="large" />
                  </IconButton>
                </Box>
              }
              <form noValidate autoComplete="off">
                <Collapse in={isDisplayAllSessions}>
                  {
                    SESSION_IDS.map(id =>
                      <TimeSetting
                        key={id}
                        id={id}
                        sc_h={inputSettings[`sc_h_${id}`]}
                        sc_m={inputSettings[`sc_m_${id}`]}
                        io_sc={inputSettings[`io_sc_${id}`]}
                        handleInputChange={handleInputChange}
                      />
                    )
                  }
                </Collapse>
                <Collapse in={!isDisplayAllSessions}>
                  <TimeSetting
                    id={displayedSessionId}
                    sc_h={inputSettings[`sc_h_${displayedSessionId}`]}
                    sc_m={inputSettings[`sc_m_${displayedSessionId}`]}
                    io_sc={inputSettings[`io_sc_${displayedSessionId}`]}
                    handleInputChange={handleInputChange}
                  />
                </Collapse>
                <Box display='flex' justifyContent='center' alignItems='top' marginBottom='16px'>
                  <Button
                    variant="contained"
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    onClick={() => setIsDisplayAllSessions(prev => !prev)}
                  >
                    {isDisplayAllSessions ?
                      "SHOW SINGLE SESSION"
                      :
                      "SHOW ALL SESSIONS"
                    }
                  </Button>
                </Box>
                <OverrideSetting
                  spwet={inputSettings.spwet}
                  iospwet={inputSettings.iospwet}
                  sppressure={inputSettings.sppressure}
                  handleInputChange={handleInputChange}
                />
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSendButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<SendIcon />}
                  >
                    CONFIRM
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickRefreshButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<RefreshIcon />}
                  >
                    REFRESH
                  </Button>
                </Box>
              </form>
              <AlertSnackbar writeSettingsResult={writeSettingsResult} />
              <AlertTimeSettings
                isOpen={isAlertTimeSettingsOpen}
                handleClose={handleCloseAlertTimeSettings}
              />
            </CardContent>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
          </Card >
        </Grid>
      </Grid>
    </section >

  );
}

import React, { useEffect, useState, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import { Button } from "reactstrap";

const ScanQr = ({ isMobile, setIsMobile, addNodeModal, setAddNodeModal }) => {


	const handleScan = data => {

		if (data) {
			setAddNodeModal(false);
			let device_id = data.split("/")[4];
			// GET SERIAL FROM DEVICE ID
			axios.get(`${process.env.REACT_APP_API_URL}/checkSerial/${device_id}`, {
				headers: {
					'Authorization': localStorage.getItem('cloudatik-access')
				}
			})
				.then(resp => {
					let serial_id = resp.data.serial;

					// REGISTER NODE
					axios.post(`${process.env.REACT_APP_API_URL}/registerNode/`, {
						'username': localStorage.getItem("cloudatik"),
						'password': sessionStorage.getItem("password"),
						'serial': serial_id
					}, {
						headers: {
							'Authorization': localStorage.getItem('cloudatik-access')
						}
					})
						.then(resp => {
							alert('Succesfully Registered Node');
						})
						.catch(err => {
							setAddNodeModal(true);
							console.log(err.response);
							alert(err.response.data.message);
						});

				}).catch(err => {
					setAddNodeModal(true);
					console.log(err.response);
					alert(err);
				});
		}
	};
	const handleError = err => {
		console.error(err);
	};


	return (
		<Modal isOpen={addNodeModal} >
			<ModalHeader toggle={() => { setAddNodeModal(false); }}>Add Node</ModalHeader>
			<ModalBody>
				{isMobile ? (
					<div>
						<QrReader
							delay={500}
							onError={handleError}
							onScan={handleScan}
							style={{ width: '100%' }}
							showViewFinder={true}
						/>
					</div>
				) : ('You need to be using Mobile Device')}
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" onClick={() => { setAddNodeModal(false); }}>Close</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ScanQr;

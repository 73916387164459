import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  diaglogTitle: {
    textAlign: 'center'
  },
  dialogActions: {
    justifyContent: 'center'
  }
}));
export default function AlertReadTimeout({
  isOpen,
  handleClose
}) {

  const classes = useStyles();

  const handleClickRefreshButton = () => {
    handleClose('refreshPage');
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      handleClose('dismissModal');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
    >
      <DialogTitle classes={{ root: classes.diaglogTitle }}>TIMEOUT</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Connection has timed out while reading device settings.
          Please refresh and try again.
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleClickRefreshButton}
          color="primary"
          autoFocus
          variant="contained"
        >
          RELOAD
        </Button>
      </DialogActions>
    </Dialog >
  );
}

import React, { useState, useEffect, Fragment } from "react";

import NotificationsNoneSharpIcon from "@material-ui/icons/NotificationsNoneSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Badge from "@material-ui/core/Badge";
import auth from "../services/auth";
import { refetchApi } from '../shared/utils';

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import profile from "../services/profile";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";

const moment = require("moment-timezone");

function Navbar() {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);

	const [dropdownOpenUser, setDropdownOpenUser] = useState(false);
	const toggleUser = () => setDropdownOpenUser((prevState) => !prevState);

	const [modalUser, setModalUser] = useState(false);
	const toggleModalUser = () => setModalUser(!modalUser);

	const [modalPassword, setModalPassword] = useState(false);
	const toggleModalPassword = () => setModalPassword(!modalPassword);

	const [notificationData, setNotificationData] = useState([]);
	const [profileUsername, setProfileUsername] = useState("");
	const [profileFullname, setProfileName] = useState("");
	const [profileCompany, setProfileCompany] = useState("");
	const [profileEmail, setProfileEmail] = useState("");
	const [profilePhone, setProfilePhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [loading, setLoading] = useState(false);

	function saveProfileDetails() {
		let dataToSubmit = {
			username: profileUsername,
			company: profileCompany,
			fullname: profileFullname,
			email: profileEmail,
			phone: profilePhone,
		};
		setLoading(true);
		profile
			.getInstance()
			.saveProfileDetails(dataToSubmit)
			.then((result) => {
				alert(result["message"]);
				setModalUser(false);
				setLoading(false);
			});
	}

	function updateProfile() {
		let dataToSubmit = {
			username: profileUsername,
			password: password,
			new_password: confirmPassword,
		};

		if (password === confirmPassword) {
			setLoading(true);
			profile
				.getInstance()
				.updateProfileDetails(dataToSubmit)
				.then((result) => {
					alert(result["message"]);
					setModalPassword(false);
				});
			// fetch(`${process.env.REACT_APP_API_URL}/changePassword/`, {
			//     method: "POST",
			//     headers: {
			//         Authorization: `Token ${process.env.REACT_APP_AUTH_TOKEN}`,
			//         "Content-Type": "application/json",
			//     },
			// body: JSON.stringify({
			//     username: profileUsername,
			//     password: password,
			//     new_password: confirmPassword,
			// }),
			// })
			//     .then((response) => response.json())
			//     .then((data) => {
			//         alert(data["message"]);
			//         setModalPassword(false);
			//     });
		} else {
			alert("Password Not Match");
		}
	}

	useEffect(() => {
		try {
			fetch(
				`${process.env.REACT_APP_API_URL
				}/getAlarmSummaryV3/${localStorage.getItem("cloudatik")}/?format=json`,
				{
					headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
				}
			)
				.then((res) => res.json())
				.then(
					(result) => {
						if (result.code && result.code === 'token_not_valid') {
							refetchApi();
						}

						if (result.alarm) {
							for (let index = 0; index < result["alarm"].length; index++) {
								//get Tag Name
								let tagName;
								let parameter_label;
								for (let m = 0; m < result["tag_name"].length; m++) {
									if (
										result["tag_name"][m]["serial"] ==
										result["alarm"][index]["serial"]
									) {
										tagName = result["tag_name"][m]["tag_name"];
										break;
									}
								}

								for (let m = 0; m < result["alarm_prop"].length; m++) {
									if (
										result["alarm_prop"][m]["parameter"] ==
										result["alarm"][index]["parameter"]
									) {
										parameter_label = result["alarm_prop"][m]["label"];
										break;
									}
								}

								// Convert Date Time
								let malaysia_date = moment(
									new Date(result["alarm"][index]["datetime"])
								)
									.tz("Asia/Kuala_Lumpur")
									.format("YYYY-MM-DD");

								let malaysia_time = moment(
									new Date(result["alarm"][index]["datetime"])
								)
									.tz("Asia/Kuala_Lumpur")
									.format("HH:MM");

								let d = {
									device_id:
										result["alarm"][index]["serial"] +
										"-" +
										result["alarm"][index]["sensor_set"],
									tag_name: tagName,
									parameter_label: parameter_label,
									date: malaysia_date,
									time: malaysia_time,
								};
								setNotificationData((notificationData) => [...notificationData, d]);
							}
						}
					},
					(error) => {
						console.log(error);
					}
				);
		} catch (error) {
			console.log(error);
		}


		setLoading(true);
		profile
			.getInstance()
			.getProfileDetails()
			.then((result) => {
				setProfileCompany(result["company"]);
				setProfileEmail(result["email"]);
				setProfileName(result["fullname"]);
				setProfilePhone(result["phone"]);
				setProfileUsername(result["username"]);
				setLoading(false);
			});
	}, []);

	return (
		<>
			{loading && <LoadingSpinner />}
			<div>
				<Modal
					isOpen={modalPassword}
					toggle={toggleModalPassword}
					className={"sm"}
				>
					<ModalHeader toggle={toggleModalPassword}>
						Change Password
					</ModalHeader>
					<ModalBody>
						<form>
							<div className="form-group">
								<label for="modal_username">Username</label>
								<input
									type="text"
									className="form-control"
									id="modal_username"
									value={profileUsername}
									disabled
								/>
							</div>
							<div className="form-group">
								<label for="modal_password">Password</label>
								<input
									type="password"
									className="form-control"
									id="modal_password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label for="modal_confirmPassword">Confirm Password</label>
								<input
									type="password"
									className="form-control"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
									id="modal_confirmPassword"
								/>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={updateProfile} disabled={localStorage.getItem("cloudatik_demo")}>
							Update Password
						</Button>{" "}
						<Button color="secondary" onClick={toggleModalPassword}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={modalUser} toggle={toggleModalUser} className={"sm"}>
					<ModalHeader toggle={toggleModalUser}>My Profile</ModalHeader>
					<ModalBody>
						<form>
							<div className="form-group">
								<label for="modal_username">Username</label>
								<input
									type="text"
									className="form-control"
									id="modal_username"
									value={profileUsername}
									disabled
								/>
							</div>
							<div className="form-group">
								<label for="modal_company">Company</label>
								<input
									type="text"
									className="form-control"
									id="modal_company"
									value={profileCompany}
									onChange={(e) => setProfileCompany(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label for="modal_fullname">Fulll Name</label>
								<input
									type="text"
									className="form-control"
									id="modal_fullname"
									value={profileFullname}
									onChange={(e) => setProfileName(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label for="modal_email">Email</label>
								<input
									type="text"
									className="form-control"
									id="modal_email"
									value={profileEmail}
									onChange={(e) => setProfileEmail(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<label for="modal_phone">Phone</label>
								<input
									type="text"
									className="form-control"
									id="modal_phone"
									value={profilePhone}
									onChange={(e) => setProfilePhone(e.target.value)}
								/>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={saveProfileDetails} disabled={localStorage.getItem("cloudatik_demo")}>
							Update Profile
						</Button>{" "}
						<Button color="secondary" onClick={toggleModalUser}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>

			<nav id="navbar" className="navbar md navbar-light bg-light">
				<Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
					<>
						<DropdownToggle
							style={{
								backgroundColor: "transparent",
								border: 0,
							}}
						>
							<Tooltip title="Notification" TransitionComponent={Zoom} arrow>
								<Badge badgeContent={notificationData.length} color="primary">
									<NotificationsNoneSharpIcon
										style={{ fontSize: 40, color: "black" }}
									/>
								</Badge>
							</Tooltip>
						</DropdownToggle>
						<DropdownMenu
							className="overflow-auto"
							style={{ height: "70vh", width: "50vw", maxWidth: "400px" }}
						>
							{notificationData.map((item, index) => (
								<Fragment key={index}>
									<DropdownItem>
										<div>
											<div className="row" style={{ width: "100%" }}>
												<>
													<div className="col-md-4">
														<p
															style={{
																color: "white",
																backgroundColor: "orange",
															}}
														>
															{item.device_id}
														</p>
													</div>
													<div className="col-md-8">
														<div className="row">
															<b>{item.tag_name}</b>
														</div>
														<div className="row">{item.parameter_label}</div>
														<div className="row">
															<div className="col-6">{item.date}</div>
															<div className="col-6">{item.time}</div>
														</div>
													</div>
												</>
											</div>
										</div>
									</DropdownItem>
									<div className="dropdown-divider"></div>
								</Fragment>
							))}
						</DropdownMenu>
					</>
				</Dropdown>
				<a href="#/v2/dashboard">
					<img
						src={process.env.REACT_APP_FARMSENS_LOGO}
						alt="apple icon"
						className="d-flex justify-content-center mx-auto"
						id="icon"
						width="120px"
					/>
				</a>
				<Dropdown isOpen={dropdownOpenUser} toggle={toggleUser}>
					<>
						<DropdownToggle
							caret
							style={{
								backgroundColor: "transparent",
								color: "black",
							}}
						>
							Hello, <b>{localStorage.getItem("cloudatik")}</b>
						</DropdownToggle>
						<DropdownMenu right>
							<DropdownItem onClick={toggleModalUser}>My Profile</DropdownItem>
							<DropdownItem onClick={toggleModalPassword}>
								Change Password
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem
								onClick={() => {
									sessionStorage.clear();
									localStorage.removeItem("cloudatik");
									localStorage.removeItem("cloudatik_demo");
									window.location.href = "/";
								}}
							>
								LogOut
							</DropdownItem>
						</DropdownMenu>
					</>
				</Dropdown>
			</nav>
		</>
	);
}

export default Navbar;

var moment = require("moment-timezone");

/**
 *
 * @param dt UTC datetime format (YYYY-MM-DD[T]HH:mm:ss)
 * @returns [isActive, days, hours, minutes]
 */
export function nodeLastUpdateDuration(dt) {
	let current_malaysia_datetime = moment()
		.tz("Asia/Kuala_Lumpur")
		.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
	let received_malaysia_datetime = moment(moment.utc(dt).valueOf())
		.tz("Asia/Kuala_Lumpur")
		.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
	var diff = moment.duration(
		moment(current_malaysia_datetime).diff(moment(received_malaysia_datetime))
	);
	var days = parseInt(diff.asDays());

	var hours = parseInt(diff.asHours());

	hours = hours - days * 24;

	var minutes = parseInt(diff.asMinutes());

	minutes = minutes - (days * 24 * 60 + hours * 60);

	if (minutes >= 10 || days > 0 || hours > 0) {
		return [false, days, hours, minutes];
	} else {
		return [true, days, hours, minutes];
	}
}

/**
 *
 * @param dt UTC datetime format (YYYY-MM-DDTHH:mm:ss)
 * @returns MYT datetime format (YYYY-MM-DDTHH:mm:ss)
 */
export function utcToMtyConvertion(dt) {
	return moment(dt).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss[Z]");
}

/**
 *
 * @param dt UTC datetime format (YYYY-MM-DDTHH:mm:ssZ)
 * @returns MYT datetime format (YYYY-MM-DD, HH:mm)
 */
export function utcToMtyConvertionDisplay(dt) {
	return moment(dt).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD, HH:mm");
}

/**
 *
 * @param dt UTC datetime format (YYYY-MM-DDTHH:mm:ssZ)
 * @returns MYT datetime (Javascript New Date format)
 */
export function utcToMtyConvertionDate(dt) {
	return new Date(
		moment(dt).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD[T]HH:mm:ss")
	);
}

/**
 *
 * @param dt UTC datetime format (YYYY-MM-DDTHH:mm:ssZ)
 * @returns boolean
 */
export function checkNodeIsActive(dt) {
	let current_malaysia_datetime = moment()
		.tz("Asia/Kuala_Lumpur")
		.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
	let received_malaysia_datetime = moment(dt)
		.tz("Asia/Kuala_Lumpur")
		.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
	var diff = moment.duration(
		moment(current_malaysia_datetime).diff(moment(received_malaysia_datetime))
	);
	var days = parseInt(diff.asDays());
	var hours = parseInt(diff.asHours());
	hours = hours - days * 24;
	var minutes = parseInt(diff.asMinutes());
	minutes = minutes - (days * 24 * 60 + hours * 60);
	if (minutes >= 10 || days > 0 || hours > 0) {
		return false;
	} else {
		return true;
	}
}

/**
 *
 * @param hours Hours going to revert
 * @returns new Date()
 */
export function formatDate(hours) {
	var det = new Date(new Date().setHours(new Date().getHours() - hours));
	// var d = new Date(date),
	// 	month = "" + (d.getMonth() + 1),
	// 	day = "" + d.getDate(),
	// 	year = d.getFullYear();
	//
	// if (month.length < 2) month = "0" + month;
	// if (day.length < 2) day = "0" + day;

	return det;
}

import React, { useEffect, useState } from 'react';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Backdrop, CircularProgress, IconButton, TablePagination } from '@material-ui/core';
import { Create as CreateIcon, Delete as DeleteIcon, CheckRounded as CheckRoundedIcon, ClearRounded as ClearRoundedIcon, ArrowBackIosRounded as ArrowBackIosRoundedIcon } from '@material-ui/icons';
import axios from 'axios';
import AddEditModal from '../Components/Schedueler/AddEditModal';

import { refetchApi } from '../shared/utils';

const url_cl_ds = `${process.env.REACT_APP_API_URL}/getRemoteScheduleAgridose/`;
const config = {
    headers: {
        'Authorization': localStorage.getItem('cloudatik-access'),
        "Content-Type": "application/json",
    }
};
let type = 'add';
let current_setting_id = 0;
export default function Schedueler({ device_id }) {
    const [tableData, setTableData] = useState([]);
    const [inputOptions, setInputOptions] = useState([]);
    const [addEditStatus, setAddEditStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [select, setSelect] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [serialId, setSerialId] = useState('');


    // PAGINATION
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);
        fetch(url_cl_ds + device_id, {
            headers: {
                'Authorization': localStorage.getItem('cloudatik-access')
            }
        }).then((res) => {
            return res.json();
        }).
            then(res => {
                if (res.code && res.code === 'token_not_valid') {
                    refetchApi();
                }

                setLoading(false);
                setTableData(res.data.schedule);
                setInputOptions(res.data.input);

                // GET LATEST setting_id AND serial
                for (let i in res.data.schedule) {
                    if (res.data.schedule[i]['setting_id'] > current_setting_id) {
                        current_setting_id = parseInt(res.data.schedule[i]['setting_id']);
                    }
                    setSerialId(res.data.schedule[i]['serial']);
                }
            });
    };

    function handleSubmit(data) {
        if (type == 'add') {
            createData(data);
        } else if (type == 'edit') {
            updateDate(data);
        }
    }
    function createData(data) {
        setSubmitting(true);
        // TODO
        data = {
            ...data,
            'serial': serialId,
            'setting_id': parseInt(current_setting_id) + 1,
        };

        axios.post(`${process.env.REACT_APP_API_URL}/saveRemoteScheduleAgridose/${device_id}/`, {
            "schedule": [data]
        }, config).then(res => {
            setSubmitting(false);
            getData();
            setAddEditStatus(false);
            alert(res.data.message);
        }).catch(err => {
            setSubmitting(false);
            console.log(err);
        });
    }

    function updateDate(data) {
        setSubmitting(true);
        axios.put(`${process.env.REACT_APP_API_URL}/updateRemoteScheduleAgridose/`, data, config)
            .then(res => {
                setSubmitting(false);
                getData();
                setAddEditStatus(false);
                alert(res.data.message);
            }).catch(err => {
                setSubmitting(false);
                console.log(err);
            });
    }

    function deleteData(id) {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/deleteRemoteScheduleAgridose/`,
            {
                'delete': [{
                    'id': id
                }]
            }
            , config)
            .then(res => {
                setLoading(false);
                getData();
                alert(res.data.message);
            }).catch(err => {
                setLoading(false);
                console.log(err);
            });
    }

    function deleteAll() {
        if (tableData.length == 0) return alert('Nothing to delete.');

        let delete_data = [];
        for (let i of tableData) {
            delete_data.push({
                'id': i.id
            });
        }
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/deleteRemoteScheduleAgridose/`,
            {
                'delete': delete_data
            }
            , config)
            .then(res => {
                setLoading(false);
                getData();
                alert(res.data.message);
            }).catch(err => {
                setLoading(false);
                console.log(err);
            });


    }

    return (
        <div>
            <Backdrop open={loading} onClick={() => { setLoading(false); }} style={styles.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>

            <div style={{ display: 'flex', margin: '20px 0' }}>
                <Button variant="contained" color="default" style={{ fontSize: '15px', flex: 1 }}
                    startIcon={<ArrowBackIosRoundedIcon />}
                    onClick={() => {
                        return window.location.href = "#/v2/dashboard";
                    }}>Back</Button>
                <h3 style={{ flex: 19, textAlign: 'center' }}>{serialId}</h3>
                <Button variant="contained" color="primary" style={{ fontSize: '15px', flex: 1, marginRight: '10px' }} onClick={() => {
                    if (window.confirm('Are you sure you want to delete all records?')) {
                        deleteAll();
                    }
                }}>Clear</Button>
                <Button variant="contained" color="primary" style={{ fontSize: '15px', flex: 1 }} onClick={() => {
                    type = 'add';
                    setSelect({});
                    setAddEditStatus(true);
                }}>Add</Button>
            </div>

            <AddEditModal
                options={inputOptions}
                open={addEditStatus}
                close={() => { setAddEditStatus(false); }}
                submit={(v) => { handleSubmit(v); }}
                submitting={submitting}
                data={select}
            />

            <TableContainer component={Paper}>
                <Table style={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>ID</TableCell> */}
                            <TableCell>Schedule</TableCell>
                            {/* <TableCell align="right">Setting ID</TableCell> */}
                            <TableCell align="right">EC Set Point</TableCell>
                            <TableCell align="right">delta ED Set Point</TableCell>
                            <TableCell align="right">pH Set Point</TableCell>
                            <TableCell align="right">delta pH Set Point</TableCell>
                            <TableCell align="right">Adding</TableCell>
                            {/* <TableCell align="right">Datetime</TableCell> */}
                            <TableCell align="right">System Updated</TableCell>
                            {/* <TableCell align="right">Success Datetime</TableCell> */}
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.length > 0 &&
                            tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(val => {
                                return (
                                    <TableRow >
                                        {/* <TableCell component="th" scope="row">{val.id}</TableCell> */}
                                        <TableCell component="th" scope="row">{val.schedule}</TableCell>
                                        {/* <TableCell align="right">{val.setting_id}</TableCell> */}
                                        <TableCell align="right">{val.sphec}</TableCell>
                                        <TableCell align="right">{val.splec}</TableCell>
                                        <TableCell align="right">{val.sphph}</TableCell>
                                        <TableCell align="right">{val.splph}</TableCell>
                                        <TableCell align="right">{val.addcl}</TableCell>
                                        {/* <TableCell align="right">{val.datetime}</TableCell> */}
                                        <TableCell align="right">{val.success ? (
                                            <CheckRoundedIcon />
                                        ) : (
                                            <ClearRoundedIcon />
                                        )}</TableCell>
                                        {/* <TableCell align="right">{val.success_datetime}</TableCell> */}
                                        <TableCell align="right">
                                            <IconButton aria-label="update" onClick={() => {
                                                type = 'edit';
                                                setSelect(val);
                                                setAddEditStatus(true);
                                            }}>
                                                <CreateIcon color="primary" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => {
                                                if (window.confirm('Are you sure you want to delete schedule?')) {
                                                    deleteData(val.id);
                                                }
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                {tableData.length == 0 && (
                    <div style={{ margin: '20px', textAlign: 'center' }}>No Data</div>
                )}
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event, newPage) => {
                    setPage(newPage);
                }}
                onChangeRowsPerPage={(event) => {
                    setRowsPerPage(+event.target.value);
                    setPage(0);
                }}
            />
        </div>
    );
}

const styles = {
    table: {
        minWidth: 650,
    },
    backdrop: {
        zIndex: 1,
        color: '#fff',
    },
};

import React, { useState } from 'react';
import Controller from '../../Containers/Controller';
import RemoteFertigation from '../../Containers/RemoteFertigation/Dashboard';
import Schedueler from '../../Containers/Schedueler';
import { useParams } from "react-router-dom";

import { Grid, Tabs, Tab, AppBar, Paper, Button } from '@material-ui/core';

const Remote = () => {

    const [type, setType] = useState("Controller");
    let { device, device_id } = useParams();
    const deviceSeries = device.substring(0, 2).toLowerCase();

    let remoteDashboard = <>
        <Grid container
            variant="fullWidth"
            style={{ marginBottom: 20, display: 'flex' }}>
            <Grid item style={{ flex: 1, textAlign: 'center', height: '50px', width: '100%', border: '2px solid', marginLeft: '20px', borderRadius: '15px', fontSize: 15, fontWeight: 'bold' }}>
                <Button onClick={() => { setType('Controller'); }} color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Controller</Button>
            </Grid>
            <Grid item style={{ flex: 1, textAlign: 'center', height: '50px', width: '100%', border: '2px solid', marginLeft: '20px', borderRadius: '15px', fontSize: 15, fontWeight: 'bold' }}>
                <Button onClick={() => { setType('Schedueler'); }} color="primary" style={{ width: '100%', height: '100%', fontSize: '15px' }}>Schedueler</Button>
            </Grid>
        </Grid>
        {type === 'Controller' && (<Controller device_id={device_id} />)}
        {type === 'Schedueler' && (<Schedueler device_id={device_id} />)}
    </>;


    if (deviceSeries === 'af') {
        remoteDashboard = <RemoteFertigation />;
    }

    return remoteDashboard;

};

export { Remote as RemoteMainLayout };

const getLocalRefreshToken = () => {
  return localStorage.getItem('cloudatik-refresh');
};

const setLocalJwtToken = (token) => {
  localStorage.setItem('cloudatik-access', `JWT ${token}`);
};

const getJwtToken = async (refreshToken) => {
  const url = `${process.env.REACT_APP_API_URL}/token/refresh/?format=json`;
  const response = await fetch(url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "refresh": refreshToken }),
    }
  );

  const result = await response.json();

  return result.access ? result.access : null;
};

const forceLogOut = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = "/login";
};

const refetchApi = async () => {
  const refreshToken = getLocalRefreshToken();

  const jwtToken = await getJwtToken(refreshToken);

  if (jwtToken) {
    setLocalJwtToken(jwtToken);
    window.location.reload();
  } else {
    forceLogOut();
  }
};

export { refetchApi };
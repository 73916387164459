import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import OpacityIcon from '@material-ui/icons/Opacity';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useTimer from '../../hook/useTimer';
import AlertDialog from './AlertDialog';
import AlertReadTimeout from './AlertReadTimeout';
import AlertWriteTimeout from './AlertWriteTimeout';
import { WRITE_RESULT } from './constants';
import DaySettings from './DaySettings';
import FertigationBlockSetting from './FertigationBlockSetting';
import ManualOperation from './ManualOperation';
import RemoteSchedule from './RemoteSchedule';
import SessionsTimeSetting from './SessionsTimeSetting';
import PidSettings from './PidSettings';
import TuneIcon from '@material-ui/icons/Tune';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 30,
        marginTop: -30,
        backgroundColor: grey[50]
    },
    button: {
        fontSize: '1rem',
        backgroundColor: blue[50],
        '&:disabled': {
            color: blue[50],
            backgroundColor: blue[500]
        }
    },
    disabledButton: {},
    gridContainer: {
        marginBottom: 12
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    formControl: {
        width: '100%'
    },
    inputLabel: {
        backgroundColor: grey[50],
        paddingRight: 4
    },
    inputLabelFocused: {
        backgroundColor: 'red',
        paddingRight: 4
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center'
    },
    select: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: 2
    },
    heading: {
        fontSize: '1.2rem',
        marginBottom: 0
    },
    alert: {
        width: '100%',
        marginBottom: 20
    }
}));

const mqtt = require('mqtt');

const mqtt_username = process.env.REACT_APP_MQTT_USERNAME;
const mqtt_password = process.env.REACT_APP_MQTT_PASSWORD;
const mqtt_server = process.env.REACT_APP_MQTT_SERVER;
const mqttServerUrl = `wss://${mqtt_server}:9001`;

export default function Dashboard() {
    const username = localStorage.getItem("cloudatik");
    const { device, device_id } = useParams();

    const topicReadDeviceSettingsRequest = `farm/srrd/${device_id}`;
    const topicReadDeviceSettingsResponse = `farm/srrp/${device_id}`;
    const topicWriteDeviceSettingsRequest = `farm/aswr/${device_id}`;
    const topicWriteDeviceSettingsResponse = `farm/aswa/${device_id}`;

    const controlPanelFor = {
        manualOperation: "manual operation",
        fertigationBlockSetting: "fertigation block setting",
        sessionsTimeSetting: "sessions time setting",
        remoteSchedule: "remote schedule",
        daySettings: "day settings",
        pidSettings: "pid settings"
    };

    const deviceIdFor = {
        manual: "manual",
        schedule: "schedule",
        block1: "block1",
        block2: "block2",
        block3: "block3",
        block4: "block4",
        block5: "block5",
        day: "day",
        house1: "house1",
        house2: "house2"
    };

    const { timer, handleTimerStart, handleTimerReset } = useTimer();

    const [isWriteSettingsInitiated, setIsWriteSettingsInitiated] = useState(false);
    const [isWriteSettingsCompleted, setIsWriteSettingsCompleted] = useState(false);
    const [isReadSettingsInitiated, setIsReadSettingsInitiated] = useState(false);
    const [isReadSettingsCompleted, setIsReadSettingsCompleted] = useState(false);

    const [writeSettingsResult, setWriteSettingsResult] = useState(null);

    const [currentControlPanel, setCurrentControlPanel] = useState(controlPanelFor.sessionsTimeSetting);
    const [targetControlPanel, setTargetControlPanel] = useState(null);
    const [mqttClient, setMqttClient] = useState(null);

    const [manualSettings, setManualSettings] = useState(null);
    const [blockSettings, setBlockSettings] = useState(null);
    const [scheduleSettings, setScheduleSettings] = useState(null);
    const [daySettings, setDaySettings] = useState(null);
    const [houseSettings, setHouseSettings] = useState(null);

    const [manualOperationsMode, setManualOperationsMode] = useState(0);

    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [isReadTimedOut, setIsReadTimedOut] = useState(false);
    const [isWriteTimedOut, setIsWriteTimedOut] = useState(false);

    const handleCloseAlertDialog = ({ isConfirm }) => {
        if (isConfirm) {
            setWriteSettingsResult(null);
            setCurrentControlPanel(targetControlPanel);
        }
        setIsAlertDialogOpen(false);
    };

    useEffect(() => {
        connectToMqttServer();
        return () => {
            disconnectFromMqttServer();
        };
    }, []);


    useEffect(() => {
        if (isWriteSettingsInitiated) {
            if (timer === 10 && !isWriteSettingsCompleted) {
                setIsWriteTimedOut(true);
                setIsWriteSettingsInitiated(false);
                handleTimerReset();
            }
        }
        if (isReadSettingsInitiated) {
            if (timer === 10 && !isReadSettingsCompleted) {
                setIsReadTimedOut(true);
                setIsReadSettingsInitiated(false);
                handleTimerReset();
            }
        }
    }, [timer]);

    const checkForManualOperationsMode = () => {
        const payload = {
            messageId: "rdmn",
            devices: "manual",
            mode: 2
        };
        readDeviceSettings(payload);
    };

    useEffect(() => {
        if (mqttClient) {
            checkForManualOperationsMode();
        }
    }, [mqttClient]);

    const connectToMqttServer = () => {
        if (mqttClient) {
            console.log(`[ERROR] MQTT client with ID ${mqttClient.options.clientId} is currently connected.`);
        } else {
            console.log("[INFO] Connecting to MQTT server...");
            const client = mqtt.connect(mqttServerUrl, {
                username: mqtt_username,
                password: mqtt_password
            });

            client.on('connect', () => {
                console.log("[INFO] Client connected.");
                setMqttClient(client);
            });

            client.on('message', (topic, message) => {
                console.log("[INFO] Message received from MQTT server.");
                console.log(`[INFO] Message from topic: ${topic}`);
                if (topic === topicReadDeviceSettingsResponse) {
                    const response = JSON.parse(message.toString());
                    const receivedDeviceSettings = response.devices[0];
                    console.log("[INFO] Received device settings", receivedDeviceSettings);
                    setIsReadSettingsCompleted(true);
                    setIsReadSettingsInitiated(false);
                    if (receivedDeviceSettings.deviceId === deviceIdFor.manual) {
                        console.log("[INFO] Received manual settings.");
                        setManualSettings(receivedDeviceSettings);
                        setManualOperationsMode(receivedDeviceSettings.deviceData.mode);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.schedule) {
                        console.log("[INFO] Received schedule settings.");
                        setScheduleSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.block1) {
                        console.log("[INFO] Received block1 settings.");
                        setBlockSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.block2) {
                        console.log("[INFO] Received block2 settings.");
                        setBlockSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.block3) {
                        console.log("[INFO] Received block3 settings.");
                        setBlockSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.block4) {
                        console.log("[INFO] Received block4 settings.");
                        setBlockSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.block5) {
                        console.log("[INFO] Received block5 settings.");
                        setBlockSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.day) {
                        console.log("[INFO] Received day settings.");
                        setDaySettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.house1) {
                        console.log("[INFO] Received house1 settings.");
                        setHouseSettings(receivedDeviceSettings);
                    }
                    if (receivedDeviceSettings.deviceId === deviceIdFor.house2) {
                        console.log("[INFO] Received house2 settings.");
                        setHouseSettings(receivedDeviceSettings);
                    }
                    console.log("[INFO] Resetting timer...");
                    handleTimerReset();
                } else if (topic === topicWriteDeviceSettingsResponse) {
                    const response = JSON.parse(message.toString());
                    console.log("[INFO] Received response for setting device settings", response);
                    setIsWriteSettingsCompleted(true);
                    setIsWriteSettingsInitiated(false);
                    if (response.result) {
                        setWriteSettingsResult(response.result);
                    } else {
                        setWriteSettingsResult(WRITE_RESULT.unexpected);
                    }
                    console.log("[INFO] handleTimerReset...");
                    handleTimerReset();
                }
                else {
                    console.log("[INFO] Message content:", message.toString());
                }
            });
        }
    };

    const disconnectFromMqttServer = () => {
        console.log("[INFO] Disconnecting client from MQTT server...");
        if (mqttClient) {
            mqttClient.end();
            mqttClient.on('end', (event) => {
                console.log("[INFO] Client disconnected.");
                setMqttClient(null);
            });
        } else {
            console.log("[ERROR] MQTT client is not connected.");
        }
    };

    const subPub = (subTopic, pubTopic, payload) => {
        if (mqttClient) {
            console.log(`[INFO] Subscribing to ${subTopic}...`);
            mqttClient.subscribe(subTopic, (err) => {
                if (err) {
                    console.log(`[ERROR] Unable to subscribe to ${subTopic}.`);
                    console.log(err);
                } else {
                    console.log(`[INFO] Subscribed to ${subTopic}.`);
                    console.log(`[INFO] Publishing to ${pubTopic}...`);
                    console.log("[INFO] Publishing payload: ", payload);
                    mqttClient.publish(pubTopic, JSON.stringify(payload), (err) => {
                        if (err) {
                            console.log(`[ERROR] Publishing to ${pubTopic}`);
                            console.log(err);
                            alert(err);
                        } else {
                            console.log(`[INFO] Published to ${pubTopic}.`);
                            if (pubTopic === topicWriteDeviceSettingsRequest) {
                                setIsWriteSettingsCompleted(false);
                                setIsWriteSettingsInitiated(true);
                                setWriteSettingsResult(null);
                                console.log("[INFO] Write device settings initiated.");
                                console.log("[INFO] handleTimerStart...");
                                handleTimerStart();
                            }
                            if (pubTopic === topicReadDeviceSettingsRequest) {
                                setIsReadSettingsCompleted(false);
                                setIsReadSettingsInitiated(true);
                                console.log("[INFO] Read device settings initiated.");
                                console.log("[INFO] handleTimerStart...");
                                handleTimerStart();
                            }
                        }
                    });
                }
            });
        } else {
            console.log("[ERROR] MQTT client is not connected.");
            alert("[ERROR] MQTT client is not connected. Please refresh page.");
        }
    };

    const readDeviceSettings = (payload) => {
        subPub(topicReadDeviceSettingsResponse, topicReadDeviceSettingsRequest, payload);
    };

    const writeDeviceSettings = (payload) => {
        subPub(topicWriteDeviceSettingsResponse, topicWriteDeviceSettingsRequest, payload);
    };

    const handleReadTimedOut = (nextAction) => {
        setIsReadTimedOut(false);
        if (nextAction === 'refreshPage') {
            window.location.reload();
        }
    };

    const handleWriteTimedOut = () => {
        setIsWriteTimedOut(false);
    };

    let displayedControlPanel = null;

    switch (currentControlPanel) {
        case controlPanelFor.manualOperation:
            displayedControlPanel = <ManualOperation
                mqttClient={mqttClient}
                readManualSettings={readDeviceSettings}
                writeManualSettings={writeDeviceSettings}
                isReadSettingsInitiated={isReadSettingsInitiated}
                isWriteSettingsInitiated={isWriteSettingsInitiated}
                writeSettingsResult={writeSettingsResult}
                manualSettings={manualSettings}
            />;
            break;
        case controlPanelFor.fertigationBlockSetting:
            displayedControlPanel = <FertigationBlockSetting
                mqttClient={mqttClient}
                readBlockSettings={readDeviceSettings}
                writeBlockSettings={writeDeviceSettings}
                isReadSettingsInitiated={isReadSettingsInitiated}
                isWriteSettingsInitiated={isWriteSettingsInitiated}
                writeSettingsResult={writeSettingsResult}
                blockSettings={blockSettings}
            />;
            break;
        case controlPanelFor.sessionsTimeSetting:
            displayedControlPanel = <SessionsTimeSetting
                mqttClient={mqttClient}
                readScheduleSettings={readDeviceSettings}
                writeScheduleSettings={writeDeviceSettings}
                isReadSettingsInitiated={isReadSettingsInitiated}
                isWriteSettingsInitiated={isWriteSettingsInitiated}
                writeSettingsResult={writeSettingsResult}
                scheduleSettings={scheduleSettings}
            />;
            break;
        case controlPanelFor.remoteSchedule:
            displayedControlPanel = <RemoteSchedule />;
            break;
        case controlPanelFor.daySettings:
            displayedControlPanel = <DaySettings
                mqttClient={mqttClient}
                readDaySettings={readDeviceSettings}
                writeDaySettings={writeDeviceSettings}
                isReadSettingsInitiated={isReadSettingsInitiated}
                isWriteSettingsInitiated={isWriteSettingsInitiated}
                writeSettingsResult={writeSettingsResult}
                daySettings={daySettings}
            />;
            break;
        case controlPanelFor.pidSettings:
            displayedControlPanel = <PidSettings
                mqttClient={mqttClient}
                readPidSettings={readDeviceSettings}
                writePidSettings={writeDeviceSettings}
                isReadSettingsInitiated={isReadSettingsInitiated}
                isWriteSettingsInitiated={isWriteSettingsInitiated}
                writeSettingsResult={writeSettingsResult}
                pidSettings={houseSettings}
            />;
            break;
    }

    const switchControlPanelTo = (target) => {
        setTargetControlPanel(target);
        setIsAlertDialogOpen(true);
    };

    const classes = useStyles();

    const handleSelect = (event) => {
        switchControlPanelTo(event.target.value);
    };

    const userName = localStorage.getItem("cloudatik");
    const is_admin = (userName === 'agri' || userName === 'autoflo') ? true : false;

    return (
        <div className={classes.root}>
            <Grid container spacing={3} justifyContent='center' classes={{ root: classes.gridContainer }}>
                <Grid item xs={12} lg={4} classes={{ root: classes.gridItem }}>
                    {!!manualOperationsMode &&
                        <Alert severity="warning" classes={{ root: classes.alert }}>
                            SYSTEM IN <strong>MANUAL MODE</strong>
                        </Alert>
                    }
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel
                            id="control-panel-label"
                            classes={{ root: classes.inputLabel, focused: classes.inputLabel }}
                        >
                            CONTROL PANEL
                        </InputLabel>
                        <Select
                            labelId="control-panel-label"
                            id="control-panel-select"
                            value={currentControlPanel}
                            onChange={handleSelect}
                            label="Control Panel"
                            classes={{ root: classes.select }}
                        >
                            <MenuItem
                                value={controlPanelFor.manualOperation}
                                classes={{ root: classes.menuItem }}
                            >
                                <BuildIcon classes={{ root: classes.icon }} />
                                <h3 className={classes.heading}>
                                    MANUAL OPERATION
                                </h3>
                            </MenuItem>
                            <MenuItem
                                value={controlPanelFor.daySettings}
                                classes={{ root: classes.menuItem }}
                            >
                                <WbSunnyIcon classes={{ root: classes.icon }} />
                                <h3 className={classes.heading}>
                                    DAY SETTINGS
                                </h3>
                            </MenuItem>
                            <MenuItem
                                value={controlPanelFor.sessionsTimeSetting}
                                classes={{ root: classes.menuItem }}
                            >
                                <ScheduleIcon classes={{ root: classes.icon }} />
                                <h3 className={classes.heading}>
                                    SESSIONS TIME SETTING
                                </h3>
                            </MenuItem>
                            <MenuItem
                                value={controlPanelFor.fertigationBlockSetting}
                                classes={{ root: classes.menuItem }}
                            >
                                <OpacityIcon classes={{ root: classes.icon }} />
                                <h3 className={classes.heading}>
                                    BLOCK SETTING
                                </h3>
                            </MenuItem>
                            <MenuItem
                                value={controlPanelFor.remoteSchedule}
                                classes={{ root: classes.menuItem }}
                            >
                                <CalendarTodayIcon classes={{ root: classes.icon }} />
                                <h3 className={classes.heading}>
                                    REMOTE SCHEDULE
                                </h3>
                            </MenuItem>
                            {is_admin &&
                                <MenuItem
                                    value={controlPanelFor.pidSettings}
                                    classes={{ root: classes.menuItem }}
                                >
                                    <TuneIcon classes={{ root: classes.icon }} />
                                    <h3 className={classes.heading}>
                                        PID SETTINGS
                                    </h3>
                                </MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {displayedControlPanel}
            <AlertDialog
                isOpen={isAlertDialogOpen}
                handleClose={handleCloseAlertDialog}
                currentControlPanel={currentControlPanel}
            />
            <AlertReadTimeout
                isOpen={isReadTimedOut}
                handleClose={(nextAction) => handleReadTimedOut(nextAction)}
            />
            <AlertWriteTimeout
                isOpen={isWriteTimedOut}
                handleClose={(handleWriteTimedOut)}
            />
        </div >
    );
};;

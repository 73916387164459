import React, { useEffect, useState } from "react";
import "./card-category.css";
import CardSingleNode from "../single-node";

function CardCategory({ categoryName, node, ms }) {
  node = node === undefined ? [] : node;

  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(node.length === 0 && true);

  const onRedirect = (device, id) => {
    window.location.href = "#/v2/node?device=" + device + "&device_id=" + id;
  };

  useEffect(() => {
    setIsEmpty(node.length === 0 && true);
  }, [node]);

  const cardHeaderColors = [];
  // const cardHeaderColors = [
  //   "#FFADAD", "#FFD6A5", "#FDFFB6", "#CAFFBF", "#9BF6FF", "#A0C4FF", "#BDB2FF", "#FFC6FF"
  // ];

  const cardHeaderColorIndex = ms;
  const cardColorHeader = cardHeaderColors[cardHeaderColorIndex];

  return (
    <div className="card card-container h-100 mx-auto">
      <div className="card-header" style={{ backgroundColor: cardColorHeader }}>{categoryName}</div>
      <div
        className="card-body h-100"
      >
        {isEmpty && <h6 className="p-2 text-center">Category is empty.</h6>}
        {node.map((node, index) => (
          <div key={index}>
            <CardSingleNode
              node={node}
              lcdId={"category_led_" + index}
              ms={ms}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardCategory;

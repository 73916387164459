import React, { useEffect, useState } from "react";
import AddCategory from "../../Components/Card/add-category";
import CardCategory from "../../Components/Card/card-category/index";
import CardSingleNode from "../../Components/Card/single-node";
import ScanQr from "../../Containers/ScanQr/index";
import auth from "../../services/auth";
import retrieveNode from "../../services/node/retrieve-node";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";

function Dashboard() {
  const [nodeWithCategory, setNodeWithCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [nodeUncategory, setNodeUncategory] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);

  useEffect(() => {
    setLoading(true);
    retrieveNode
      .getInstance()
      .getNodeCategory()
      .then((result) => {
        setCategoryList(result["category_list"]);
        setNodeWithCategory(result["category_with_node"]);
        setNodeUncategory(result["uncategory_node"]);
        setLoading(false);
      });
  }, []);

  function closeAddCategory() {
    setShowAddCategory(false);
  }

  const [addNodeModal, setAddNodeModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const addNewNode = () => {
    setAddNodeModal(true);
    if (
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        navigator.userAgent.toLowerCase()
      )
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {showAddCategory && <AddCategory closeAddCategory={closeAddCategory} />}
      <div className="container">
        <div className="row">
          {categoryList.map((categoryName, index) => (
            <div className="col-12 col-md-3 mb-3" key={categoryName}>
              <CardCategory
                ms={index}
                categoryName={categoryName}
                node={nodeWithCategory[categoryName]}
              />
            </div>
          ))}
          <div className="col-12 col-md-3 mb-3">
            <div className="card card-container h-100 mx-auto">
              <div className="card-header">Add Category</div>
              <div className="card-body">
                <button
                  className="btn btn-info"
                  onClick={() => setShowAddCategory(true)}
                  disabled={localStorage.getItem("cloudatik_demo")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row pb-4">
          {nodeUncategory.map((node, index) => (
            <div className="col-12 col-md-3" key={node.device_id}>
              <CardSingleNode node={node} lcdId={"single_led_" + index} />
            </div>
          ))}
          <div className="col-12 col-md-3 mb-3">
            <div className="card card-container h-100 mx-auto">
              <div className="card-header">New Node</div>
              <div className="card-body">
                <button className="btn btn-info" onClick={addNewNode} disabled={localStorage.getItem("cloudatik_demo")}>
                  Add New Node
                </button>
              </div>
            </div>
          </div>

          <ScanQr
            setAddNodeModal={setAddNodeModal}
            setIsMobile={setIsMobile}
            isMobile={isMobile}
            addNodeModal={addNodeModal}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;

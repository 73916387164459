import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';


const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
  },
  button: {
    margin: '0 12px'
  }
}));

export default function BlockSessionSetting({
  id,
  medium,
  duration,
  spec,
  handleInputChange
}) {

  const classes = useStyles();

  const PARAM = {
    medium: 'medium',
    duration: 'duration',
    spec: 'spec'
  };

  const ecInputProps = {
    step: 1,
  };

  const durationInputProps = {
    step: 1,
  };

  const SESSION = {
    a: '1',
    b: '2',
    c: '3',
    d: '4',
    e: '5',
    f: '6'
  };

  const [isDurationShown, setIsDurationShown] = useState(true);
  const [isSpecShown, setIsSpecShown] = useState(true);

  useEffect(() => {
    // if OFF is selected
    if (medium === 0) {
      setIsDurationShown(false);
      setIsSpecShown(false);
    }
    // if WATER is selected
    if (medium === 1) {
      setIsDurationShown(true);
      setIsSpecShown(false);
    }
    // if FERTILIZER is selected
    if (medium === 2) {
      setIsDurationShown(true);
      setIsSpecShown(true);
    }
  }, [medium]);


  const handleMediumChange = (event) => {
    if (event.target.value === 0) {
      setIsDurationShown(false);
      setIsSpecShown(false);
    }
    if (event.target.value === 1) {
      setIsDurationShown(true);
      setIsSpecShown(false);
    }
    if (event.target.value === 2) {
      setIsDurationShown(true);
      setIsSpecShown(true);
    }
  };

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <Typography variant="h6" align='center'>
        SESSION {SESSION[id]}
      </Typography>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">TYPE</FormLabel>
        <RadioGroup
          value={medium}
          onChange={(event) => {
            handleMediumChange(event);
            handleInputChange(event, `${PARAM.medium}_${id}`);
          }
          }
        >
          <FormControlLabel value={0} control={<Radio color="primary" />} label="NONE" />
          <FormControlLabel value={1} control={<Radio color="primary" />} label="WATER" />
          <FormControlLabel value={2} control={<Radio color="primary" />} label="FERTILIZER" />
        </RadioGroup>
      </FormControl>
      {
        isDurationShown &&
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label">Duration</FormLabel>
          <Box display='flex' alignItems='center'>
            <TextField
              id="outlined-basic"
              label="min"
              variant="outlined"
              type="number"
              size='small'
              inputProps={durationInputProps}
              value={duration}
              onChange={(event) => handleInputChange(event, `${PARAM.duration}_${id}`)}
            />
          </Box>
        </FormControl>
      }
      {
        isSpecShown &&
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label">EC</FormLabel>
          <Box display='flex' alignItems='center'>
            <TextField
              id="outlined-basic"
              label="ms/cm"
              variant="outlined"
              type="number"
              size='small'
              inputProps={ecInputProps}
              value={spec}
              onChange={(event) => handleInputChange(event, `${PARAM.spec}_${id}`)}
            />
          </Box>
        </FormControl>
      }
    </Box >
  );
}

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  GridToolbarContainer
} from '@mui/x-data-grid';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap'
  },
}));

export default function CustomToolbar({
  scheduleIds,
  deleteSchedule,
  switchDisplay,
  readSchedule,
  isLoading
}) {
  const classes = useStyles();

  const handleClickDeleteButton = () => {
    const payload = {
      delete: []
    };

    scheduleIds.forEach(id => {
      payload.delete.push({
        id: id.toString()
      });
    });

    deleteSchedule(payload);
  };

  return (
    <GridToolbarContainer>
      <Box className={classes.box}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          style={{ margin: 10 }}
          onClick={() => switchDisplay('newForm')}
          disabled={isLoading}
        >
          Add
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<DeleteIcon />}
          style={{ margin: 10 }}
          onClick={handleClickDeleteButton}
          disabled={isLoading}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={readSchedule}
          style={{ margin: 10 }}
          disabled={isLoading}
        >
          Refresh
        </Button>
      </Box>
    </GridToolbarContainer >
  );
}

import { Component } from "react";
import { utcToMtyConvertionDisplay } from "../../shared/DateTimeConvertion";
import { refetchApi } from '../../shared/utils';

export class alarm extends Component {
	static myInstance = null;

	static getInstance() {
		return new alarm();
	}

	async removeAlarm(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/deleteAlarmV3/?format=json`,
				{
					headers: {
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					method: "POST",
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error(error);
		}
	}

	async getAlarmList(device_id) {

		let response = await fetch(
			`${process.env.REACT_APP_API_URL}/getAlarmHistoryV3/${device_id}?format=json`,
			{
				headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
			}
		);
		let responseJson = await response.json();
		if (responseJson.code && responseJson.code === 'token_not_valid') {
			refetchApi();
		}

		let dataToSubmit = {};
		let alarm_list = [];
		if (responseJson["alarm_prop"].length > 0) {
			dataToSubmit["serial"] = responseJson["alarm_prop"][0]["serial"];
			dataToSubmit["label"] = responseJson["alarm_prop"][0]["label"];
			dataToSubmit["chart_title"] =
				responseJson["alarm_prop"][0]["chart_title"];
		} else {
			dataToSubmit["serial"] = null;
			dataToSubmit["label"] = null;
			dataToSubmit["chart_title"] = null;
		}
		if (responseJson["history"].length > 0) {
			for (let index = 0; index < responseJson["history"].length; index++) {
				let js = {
					ack_datetime: utcToMtyConvertionDisplay(
						responseJson["history"][index]["remove_datetime"]
					),
					alarm_datetime: utcToMtyConvertionDisplay(
						responseJson["history"][index]["alarm_datetime"]
					),
					device_id: device_id,
					serial: responseJson["history"][index]["serial"],
					limitLower: responseJson["history"][index]["limitLower"],
					limitUpper: responseJson["history"][index]["limitUpper"],
					parameter: responseJson["history"][index]["parameter"],
					user_removal: responseJson["history"][index]["user_removal"],
					value: responseJson["history"][index]["value"],
				};
				for (let x = 0; x < responseJson["alarm_prop"].length; x++) {
					if (responseJson["history"][index]["parameter"] == responseJson["alarm_prop"][x]["parameter"]) {
						js["label"] = responseJson["alarm_prop"][x]["label"];
					}
				}
				alarm_list.push(js);
			}
			dataToSubmit["alarm_list"] = alarm_list;
		} else {
			dataToSubmit["alarm_list"] = [];
		}
		return dataToSubmit;

	}

	async getNewAlarm(device_id) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/getAlarmV3/${device_id}?format=json`,
				{
					headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
				}
			);
			let responseJson = await response.json();
			let dataToSubmit = {};
			let alarm_list = [];
			if (responseJson["alarm_prop"].length > 0) {
				dataToSubmit["serial"] = responseJson["alarm_prop"][0]["serial"];
				dataToSubmit["label"] = responseJson["alarm_prop"][0]["label"];
				dataToSubmit["chart_title"] =
					responseJson["alarm_prop"][0]["chart_title"];
			} else {
				dataToSubmit["serial"] = null;
				dataToSubmit["label"] = null;
				dataToSubmit["chart_title"] = null;
			}
			if (responseJson["alarm"].length > 0) {
				for (let index = 0; index < responseJson["alarm"].length; index++) {
					let js = {
						datetime: utcToMtyConvertionDisplay(
							responseJson["alarm"][index]["datetime"]
						),
						device_id: device_id,
						serial: responseJson["alarm"][index]["serial"],
						limitLower: responseJson["alarm"][index]["limit_lower"],
						limitUpper: responseJson["alarm"][index]["limit_upper"],
						parameter: responseJson["alarm"][index]["parameter"],
						value: responseJson["alarm"][index]["value"],
						alarm_id: responseJson["alarm"][index]["alarmid"]
					};
					for (let x = 0; x < responseJson["alarm_prop"].length; x++) {
						if (responseJson["alarm"][index]["parameter"] == responseJson["alarm_prop"][x]["parameter"]) {
							js["label"] = responseJson["alarm_prop"][x]["label"];
						}
					}
					alarm_list.push(js);
				}
				dataToSubmit["alarm_list"] = alarm_list;
			} else {
				dataToSubmit["alarm_list"] = [];
			}
			return dataToSubmit;
		} catch (error) {
			console.error(error);
		}
	}
}
export default alarm;

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

export default function AlertDialog({
  isOpen,
  handleClose,
  currentControlPanel
}) {

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">{`EXIT ${currentControlPanel.toUpperCase()}?`}</DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClose({ isConfirm: false })} color="primary" autoFocus>
          CANCEL
        </Button>
        <Button onClick={() => handleClose({ isConfirm: true })} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

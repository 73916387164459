import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';
import PidSetting from './PidSetting';
import StatusCard from './StatusCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: '0 12px'
  },
  heading: {
    fontSize: '1.2rem',
    marginBottom: 0,
    width: '100%',
    textAlign: 'center'
  },
  menuItem: {
    fontSize: '1.2rem',
    marginBottom: 0,
    textAlign: 'center'
  },
  select: {
    paddingLeft: '32px',
    border: `1px solid ${grey[50]}`
  },
}));

export default function PidSettings({
  mqttClient,
  pidSettings,
  readPidSettings,
  writePidSettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult
}) {

  const classes = useStyles();

  const pumpHouse = {
    one: {
      name: 'PUMP HOUSE 1',
      id: '6',
      deviceId: 'house1'
    },
    two: {
      name: 'PUMP HOUSE 2',
      id: '7',
      deviceId: 'house2'
    }
  };

  const [isConnected, setIsConnected] = useState(false);
  const [currentPumpHouse, setCurrentPumpHouse] = useState(pumpHouse.one);

  useEffect(() => {
    if (mqttClient) {
      setIsConnected(true);
      const payload = {
        messageId: "rdps",
        devices: currentPumpHouse.deviceId,
        mode: 1
      };
      readPidSettings(payload);
    } else {
      setIsConnected(false);
    }
  }, [mqttClient, currentPumpHouse]);

  const [inputSettings, setInputSettings] = useState({
    pidp6: '',
    pidi6: '',
    pidd6: '',
    method6: '',
    pidp7: '',
    pidi7: '',
    pidd7: '',
    method7: '',
  });

  useEffect(() => {
    if (pidSettings) {
      const deviceData = pidSettings.deviceData;
      setInputSettings({
        [`pidp${currentPumpHouse.id}`]: deviceData[`pidp${currentPumpHouse.id}`],
        [`pidi${currentPumpHouse.id}`]: deviceData[`pidi${currentPumpHouse.id}`],
        [`pidd${currentPumpHouse.id}`]: deviceData[`pidd${currentPumpHouse.id}`],
        [`method${currentPumpHouse.id}`]: deviceData[`method${currentPumpHouse.id}`]
      });
    }
  }, [pidSettings]);

  const handleClickSendButton = () => {

    const payload = {
      messageId: 'wrps',
      devices: [{
        deviceId: currentPumpHouse.deviceId,
        deviceData: {
          [`pidp${currentPumpHouse.id}`]: inputSettings[`pidp${currentPumpHouse.id}`],
          [`pidi${currentPumpHouse.id}`]: inputSettings[`pidi${currentPumpHouse.id}`],
          [`pidd${currentPumpHouse.id}`]: inputSettings[`pidd${currentPumpHouse.id}`],
          [`method${currentPumpHouse.id}`]: inputSettings[`method${currentPumpHouse.id}`]
        }
      }]
    };

    writePidSettings(payload);
  };

  const handleClickRefreshButton = () => {
    const payload = {
      messageId: "rdps",
      devices: currentPumpHouse.deviceId,
      mode: 1
    };
    readPidSettings(payload);
  };

  const handleInputChange = (event, param) => {
    let value = parseFloat(event.target.value);

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  const handleSelect = (event) => {

    const selectedPumpHouseName = event.target.value;

    switch (selectedPumpHouseName) {
      case pumpHouse.one.name:
        setCurrentPumpHouse(pumpHouse.one);
        break;
      case pumpHouse.two.name:
        setCurrentPumpHouse(pumpHouse.two);
        break;
    }
  };

  return (
    <section className={classes.root}>
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={12}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <StatusCard isConnected={isConnected} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
            <CardContent>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  id="pump-house-select"
                  value={currentPumpHouse.name}
                  onChange={handleSelect}
                  classes={{ root: classes.select }}
                  disabled={isWriteSettingsInitiated || isReadSettingsInitiated}
                >
                  <MenuItem
                    value={pumpHouse.one.name}
                    classes={{ root: classes.menuItem }}
                  >
                    <h3 className={classes.heading}>
                      {pumpHouse.one.name}
                    </h3>
                  </MenuItem>
                  <MenuItem
                    value={pumpHouse.two.name}
                    classes={{ root: classes.menuItem }}
                  >
                    <h3 className={classes.heading}>
                      {pumpHouse.two.name}
                    </h3>
                  </MenuItem>
                </Select>
              </FormControl>
              <form noValidate autoComplete="off">
                <PidSetting
                  houseId={currentPumpHouse.id}
                  pidp={inputSettings[`pidp${currentPumpHouse.id}`]}
                  pidi={inputSettings[`pidi${currentPumpHouse.id}`]}
                  pidd={inputSettings[`pidd${currentPumpHouse.id}`]}
                  method={inputSettings[`method${currentPumpHouse.id}`]}
                  handleInputChange={handleInputChange}
                />
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickSendButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<SendIcon />}
                  >
                    SEND
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickRefreshButton}
                    disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
                    classes={{ root: classes.button }}
                    startIcon={<RefreshIcon />}
                  >
                    REFRESH
                  </Button>
                </Box>
              </form>
              <AlertSnackbar writeSettingsResult={writeSettingsResult} />
            </CardContent>
            {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
          </Card >
        </Grid>
      </Grid>
    </section >
  );
}

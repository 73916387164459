import { Component } from "react";
import {
	checkNodeIsActive,
	nodeLastUpdateDuration,
	utcToMtyConvertion,
	utcToMtyConvertionDate,
	utcToMtyConvertionDisplay,
} from "../../shared/DateTimeConvertion";
import { refetchApi } from '../../shared/utils';

export class nodeProp extends Component {
	static myInstance = null;

	static getInstance() {
		return new nodeProp();
	}

	async getAlarmProps(device_id) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL
				}/getAlarmPropV5/${device_id}/${localStorage.getItem("cloudatik")}/`,
				{
					headers: {
						'Authorization': localStorage.getItem('cloudatik-access')
					}
				}
			);
			let responseJson = await response.json();
			if (responseJson.code && responseJson.code === 'token_not_valid') {
				refetchApi();
			}
			let data_to_return = {};
			data_to_return["single"] = {};
			data_to_return["combined"] = {};
			data_to_return["single"]["slider"] = [];
			data_to_return["single"]["switch"] = [];
			data_to_return["single"]["threshold"] = [];
			data_to_return["combined"]["slider"] = [];
			data_to_return["combined"]["switch"] = [];
			data_to_return["combined"]["threshold"] = [];
			for (let index = 0; index < responseJson["alarm_prop_single"].length; index++) {
				let js = {
					index: index,
					limit_low: responseJson["alarm_prop_single"][index]["limit_low"],
					limit_high: responseJson["alarm_prop_single"][index]["limit_high"],
					slider_max: responseJson["alarm_prop_single"][index]["slider_max"],
					slider_min: responseJson["alarm_prop_single"][index]["slider_min"],
					alarm_max: responseJson["alarm_prop_single"][index]["alarm_max"],
					alarm_min: responseJson["alarm_prop_single"][index]["alarm_min"],
					alarm_enabled: responseJson["alarm_prop_single"][index]["alarm_enabled"],
					label: responseJson["alarm_prop_single"][index]["label"],
					plot_limit: responseJson["alarm_prop_single"][index]["plot_limit"],
					parameter: responseJson["alarm_prop_single"][index]["parameter"],
					sensor_set: responseJson["sensor_set"],
					slider_step: responseJson["alarm_prop_single"][index]["slider_step"]
				};
				if (responseJson["alarm_prop_single"][index]["slider_category"] == "slider") {
					data_to_return["single"]["slider"].push(js);
				}
				// GENERATE THRESHOLD
				if (
					responseJson["alarm_prop_single"][index]["slider_category"] == "threshold"
				) {
					data_to_return["single"]["threshold"].push(js);
				}
				// GENERATE SWITCH
				if (responseJson["alarm_prop_single"][index]["slider_category"] == "switch") {
					data_to_return["single"]["switch"].push(js);
				}
			}
			for (let index = 0; index < responseJson["alarm_prop_combined"].length; index++) {
				let js = {
					index: index,
					limit_low: responseJson["alarm_prop_combined"][index]["limit_low"],
					limit_high: responseJson["alarm_prop_combined"][index]["limit_high"],
					slider_max: responseJson["alarm_prop_combined"][index]["slider_max"],
					slider_min: responseJson["alarm_prop_combined"][index]["slider_min"],
					alarm_max: responseJson["alarm_prop_combined"][index]["alarm_max"],
					alarm_min: responseJson["alarm_prop_combined"][index]["alarm_min"],
					alarm_enabled: responseJson["alarm_prop_combined"][index]["alarm_enabled"],
					label: responseJson["alarm_prop_combined"][index]["label"],
					plot_limit: responseJson["alarm_prop_combined"][index]["plot_limit"],
					parameter: responseJson["alarm_prop_combined"][index]["parameter"],
					sensor_set: responseJson["sensor_set"],
					slider_step: responseJson["alarm_prop_combined"][index]["slider_step"]
				};
				if (responseJson["alarm_prop_combined"][index]["slider_category"] == "slider") {
					data_to_return["combined"]["slider"].push(js);
				}
				// GENERATE THRESHOLD
				if (
					responseJson["alarm_prop_combined"][index]["slider_category"] == "threshold"
				) {
					data_to_return["combined"]["threshold"].push(js);
				}
				// GENERATE SWITCH
				if (responseJson["alarm_prop_combined"][index]["slider_category"] == "switch") {
					data_to_return["combined"]["switch"].push(js);
				}
			}
			return data_to_return;
		} catch (error) {
			console.error(error);
		}
	}

	async updateAlarmProps(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL
				}/saveAlarmSettingV3/?format=json`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error(error);
		}
	}
}

export default nodeProp;

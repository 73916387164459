import React, { useState, useEffect } from "react";
import alarm from "../../../services/node/alarm";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

function NewAlarm({ device_id }) {
    const [alarmList, setAlarmList] = useState([]);
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        alarm
            .getInstance()
            .getNewAlarm(device_id)
            .then((result) => {
                setAlarmList(result["alarm_list"]);
                setTitle(result["serial"]);
                setLoading(false);
            });
    }, []);

    function onRemoveAlarm(alarm_id, device_id) {
        let data = {
            device_id: device_id,
            alarm_id: alarm_id,
            username: localStorage.getItem('cloudatik')
        };
        setLoading(true);
        alarm
            .getInstance()
            .removeAlarm(data)
            .then((result) => {
                alert("Alarm Removed Successfully");
                setAlarmList(alarmList.filter((item) => item.alarm_id !== alarm_id));
                setLoading(false);
            })
            .catch((error) => {
                alert("Remove Alarm Failed.");
            });
    }

    return (
        <div className="border border-secondary rounded">
            {loading && <LoadingSpinner />}
            <BrowserView style={{ overflowX: "scroll" }}>
                {alarmList.length > 0 ? (
                    <table className="table" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Alarm Time</th>
                                <th>Serial</th>
                                <th>Parameter</th>
                                <th>Value</th>
                                <th>Lower Limit</th>
                                <th>Higher Limit</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarmList.map((item) => (
                                <tr key={`${item.serial}-${item.label}`}>
                                    <td>{item.datetime}</td>
                                    <td>{item.serial}</td>
                                    <td>{item.label}</td>
                                    <td>{item.value}</td>
                                    <td>{item.limitLower}</td>
                                    <td>{item.limitUpper}</td>
                                    <td>
                                        <button className={"btn btn-danger"} onClick={() => onRemoveAlarm(item.alarm_id, item.device_id)}>Remove</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <h3>No Alarm Data</h3>
                )}
            </BrowserView>
            <MobileView style={{ overflowX: "scroll" }}>
                {alarmList.length > 0 ? (
                    <table className="table" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Alarm Time</th>
                                <th>Serial</th>
                                <th>Parameter</th>
                                <th>Value</th>
                                <th>Lower Limit</th>
                                <th>Higher Limit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alarmList.map((item) => (
                                <tr key={`${item.serial}-${item.label}`}>
                                    <td>{item.datetime}</td>
                                    <td>{item.serial}</td>
                                    <td>{item.label}</td>
                                    <td>{item.value}</td>
                                    <td>{item.limitLower}</td>
                                    <td>{item.limitUpper}</td>
                                    <td>
                                        <button className={"btn btn-danger"} onClick={() => onRemoveAlarm(item.alarm_id, item.device_id)}>Remove</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <h3>No Alarm Data</h3>
                )}
            </MobileView>
        </div>
    );
}

export default NewAlarm;

import React, { useEffect } from "react";
import * as steelseries from "steelseries";
import { Card, Icon, Image } from 'semantic-ui-react'

function CardLcd({ index, label, value, isMsg,width = 200 }) {
    return (
        <>
            <Card color='red'>
                <Card.Content>
                    <Card.Header>{label}</Card.Header>
                    <Card.Description>
                        <h2>{value}</h2>
                    </Card.Description>
                </Card.Content>
            </Card>
            <br/>
        </>
    );
}

export default CardLcd;

import React from "react";

function EditAlarm({ device_id }) {
	return (
		<div>
			<h1>Edit Alarm</h1>
		</div>
	);
}

export default EditAlarm;

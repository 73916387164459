import React, { useState } from "react";

import { Route, HashRouter } from "react-router-dom";

import RetrieveChart from "./Components/RetrieveChart";
import PrivateRoute from "./Components/Auth/PrivateRoute";
import NodeAlarm from "./Components/NodeAlarm";
import Navbar from "./Components/Navbar";
import { RemoteMainLayout } from "./pages/Remote";

import Login from "../src/pages/Login/index";
import Dashboard from "../src/pages/Dashboard/index";
import NodeSummary from "./pages/NodeSummary";

import 'semantic-ui-css/semantic.min.css';

function App() {
	return (
		<div>
			<HashRouter>
				{localStorage.getItem("cloudatik") && <Navbar />}
				<div style={{ paddingTop: 30 }}>
					<Route path="/" component={Login} exact />
					<PrivateRoute
						path="/retrieve-chart"
						auth={localStorage.getItem("cloudatik")}
						comp={RetrieveChart}
					/>
					<PrivateRoute
						path="/node-config"
						auth={localStorage.getItem("cloudatik")}
						comp={NodeAlarm}
					/>
					<PrivateRoute
						path="/remote/:device/:device_id"
						auth={localStorage.getItem("cloudatik")}
						comp={RemoteMainLayout}
					/>

					{/* v2 */}
					<PrivateRoute
						path="/v2/dashboard"
						auth={localStorage.getItem("cloudatik")}
						comp={Dashboard}
					/>
					<PrivateRoute
						path="/v2/node"
						auth={localStorage.getItem("cloudatik")}
						comp={NodeSummary}
					/>
				</div>
			</HashRouter>
		</div>
	);
}

export default App;

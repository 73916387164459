import React, { useEffect } from "react";
import * as steelseries from "steelseries";

function LcdDisplay({ index, type, unit, latestData, width = 200 }) {
	let lcd;
	useEffect(() => {

		if (unit !== null) {

			let customUnitString = unit;
			if (customUnitString.includes("null")) {
				customUnitString = customUnitString.replace("null", '');
			}

			lcd = new steelseries.DisplaySingle(
				document.getElementById(`lcd_${type}_${index}`),
				{
					width: width,
					height: 40,
					unitString: customUnitString,
					unitStringVisible: true,
					headerStringVisible: true,
					value: parseFloat(latestData),
				}
			);
		} else {
			lcd = new steelseries.DisplaySingle(
				document.getElementById(`lcd_${type}_${index}`),
				{
					width: width,
					height: 40,
					unitStringVisible: true,
					headerStringVisible: true,
					value: parseFloat(latestData),
				}
			);
		}
	}, [latestData]);

	return (
		<>
			<canvas id={`lcd_${type}_${index}`}></canvas>
		</>
	);
}

export default LcdDisplay;

import React, { useState } from "react";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";
import EditProfile from "./EditProfile";
import RemoveNode from "./RemoveNode";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    // root: {
    //     flexGrow: 1,
    //     backgroundColor: theme.palette.background.paper,
    // },
}));

function ProfileModal({ device_id, sensor_set }) {
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <div className={classes.root} >
                <AppBar position="static" style={{ backgroundColor: "white" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Profile Modal"
                        style={{ color: "black" }}
                    >
                        <Tab label="Edit Profile" {...a11yProps(0)} />
                        <Tab label="Remove Profile" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <EditProfile device_id={device_id} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <RemoveNode device_id={device_id} sensor_set={sensor_set} />
                </TabPanel>
            </div>
        </>
    );
}

export default ProfileModal;

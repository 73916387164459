import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { WRITE_RESULT } from './constants';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertSnackbar({
  writeSettingsResult
}) {

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsSnackbarVisible(false);
  };

  useEffect(() => {
    if (writeSettingsResult === 'success' || writeSettingsResult === 'error') {
      setIsSnackbarVisible(true);
    }
  }, [writeSettingsResult]);

  let displayedAlert = null;

  switch (writeSettingsResult) {
    case WRITE_RESULT.success:
      displayedAlert =
        <Snackbar open={isSnackbarVisible} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          < Alert onClose={handleCloseSnackbar} severity="success">
            Success
          </Alert>
        </Snackbar>;
      break;
    case WRITE_RESULT.error:
      displayedAlert =
        <Snackbar open={isSnackbarVisible} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            Error
          </Alert>
        </Snackbar>;
      break;
    case WRITE_RESULT.unexpected:
      displayedAlert =
        <Snackbar open={isSnackbarVisible} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            Unexpected response
          </Alert>
        </Snackbar>;
      break;
  }

  return (
    displayedAlert
  );
}
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import nodeProfile from "../../../services/node/node-profile";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";

function EditProfile({ device_id }) {
	const [serial, setSerial] = useState("");
	const [sensor, setSensor] = useState("");
	const [tagName, setTagName] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [locationName, setLocationName] = useState("");
	const [address, setAddress] = useState("");
	const [premise, setPremise] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showEditNode, setShowEditNode] = useState(true);
	const [showRemoveNode, setShowRemoveNode] = useState(!showEditNode);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [alarmModal, setAlarmModal] = useState(false);
	const alarmModalToggle = () => setAlarmModal(!alarmModal);
	const [loading, setLoading] = useState(true);

	function onSaveNodeProfile(e) {
		e.preventDefault();
		let dataToSubmit = {
			serial: sensor,
			tag_name: tagName,
			location_name: locationName,
			address: address,
			latitude: latitude,
			longitude: longitude,
			premise: premise,
		};
		setLoading(true);
		nodeProfile
			.getInstance()
			.updateNodeProfile(device_id, dataToSubmit)
			.then((result) => {
				setLoading(false);
				alert("Node Profile Updated");
			});
	}

	useEffect(() => {
		setLoading(true);
		nodeProfile
			.getInstance()
			.getNodeProfile(device_id)
			.then((result) => {
				setSerial(result["serial"]);
				setSensor(result["sensor"]);
				setTagName(result["tag_name"]);
				setLatitude(result["latitude"]);
				setLongitude(result["longitude"]);
				setLocationName(result["location_name"]);
				setAddress(result["address"]);
				setPremise(result["premise"]);
				setLoading(false);
			});
	}, []);

	return (
		<>
			{loading && <LoadingSpinner />}
			<form onSubmit={onSaveNodeProfile}>
				<div className="form-group">
					<label>Serial</label>
					<input
						type="text"
						className="form-control"
						id="txt_serial"
						value={serial}
						disabled
					/>
				</div>
				<div className="form-group">
					<label>Sensor</label>
					<input
						type="text"
						className="form-control"
						id="txt_sensor"
						value={sensor}
						disabled
					/>
				</div>
				<div className="form-group">
					<label>Tag Name</label>
					<input
						type="text"
						className="form-control"
						id="txt_tagName"
						value={tagName}
						onChange={(e) => setTagName(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Latitude</label>
					<input
						type="number"
						className="form-control"
						id="txt_latitude"
						value={latitude}
						onChange={(e) => setLatitude(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Longitude</label>
					<input
						type="number"
						className="form-control"
						id="txt_longitude"
						value={longitude}
						onChange={(e) => setLongitude(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Location Name</label>
					<input
						type="text"
						className="form-control"
						id="txt_locationName"
						value={locationName}
						onChange={(e) => setLocationName(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Address</label>
					<input
						type="text"
						className="form-control"
						id="txt_address"
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						required
					/>
				</div>
				<div className="form-group">
					<label>Premise</label>
					<input
						type="text"
						className="form-control"
						id="txt_premise"
						value={premise}
						onChange={(e) => setPremise(e.target.value)}
						required
					/>
				</div>
				<Button type="submit" color="primary" disabled={localStorage.getItem("cloudatik_demo")}>
					Save Changes
				</Button>{" "}
			</form>
		</>
	);
}

export default EditProfile;

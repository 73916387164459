import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListIcon from '@material-ui/icons/List';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import AlertSnackbar from './AlertSnackbar';
import BlockSessionSetting from './BlockSessionSetting';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
  },
  button: {
    margin: '0 12px'
  },
  menuItem: {
    fontSize: '1.2rem',
    marginBottom: 0,
    textAlign: 'center'
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  select: {
    paddingLeft: '32px',
    border: `1px solid ${grey[50]}`
  },
  heading: {
    fontSize: '1.4rem',
    marginBottom: 0,
    width: '100%',
    textAlign: 'center'
  }
}));

const SESSION_IDS = ['a', 'b', 'c', 'd', 'e', 'f'];
const BLOCK_IDS = [1, 2, 3, 4, 5];

export default function BlockSetting({
  id,
  blockSettings,
  readBlockSettings,
  writeBlockSettings,
  isWriteSettingsInitiated,
  isReadSettingsInitiated,
  writeSettingsResult,
  handleSelectBlock
}) {

  const classes = useStyles();

  const [isDisplayAllSessions, setIsDisplayAllSessions] = useState(false);
  const [displayedSessionId, setDisplayedSessionId] = useState('a');

  const [inputSettings, setInputSettings] = useState({
    medium_a: '',
    duration_a: '',
    spec_a: '',
    medium_b: '',
    duration_b: '',
    spec_b: '',
    medium_c: '',
    duration_c: '',
    spec_c: '',
    medium_d: '',
    duration_d: '',
    spec_d: '',
    medium_e: '',
    duration_e: '',
    spec_e: '',
    medium_f: '',
    duration_f: '',
    spec_f: '',
  });

  useEffect(() => {
    if (blockSettings) {
      setInputSettings({
        medium_a: blockSettings.medium_a,
        duration_a: blockSettings.duration_a,
        spec_a: blockSettings.spec_a,
        medium_b: blockSettings.medium_b,
        duration_b: blockSettings.duration_b,
        spec_b: blockSettings.spec_b,
        medium_c: blockSettings.medium_c,
        duration_c: blockSettings.duration_c,
        spec_c: blockSettings.spec_c,
        medium_d: blockSettings.medium_d,
        duration_d: blockSettings.duration_d,
        spec_d: blockSettings.spec_d,
        medium_e: blockSettings.medium_e,
        duration_e: blockSettings.duration_e,
        spec_e: blockSettings.spec_e,
        medium_f: blockSettings.medium_f,
        duration_f: blockSettings.duration_f,
        spec_f: blockSettings.spec_f,
      });
    }
  }, [blockSettings]);

  const handleClickSendButton = () => {

    const payload = {
      messageId: `wrb${id}`,
      devices: [{
        deviceId: `block${id}`,
        deviceData: {
          [`medium_a${id}`]: inputSettings.medium_a,
          [`duration_a${id}`]: inputSettings.duration_a,
          [`spec_a${id}`]: inputSettings.spec_a,
          [`medium_b${id}`]: inputSettings.medium_b,
          [`duration_b${id}`]: inputSettings.duration_b,
          [`spec_b${id}`]: inputSettings.spec_b,
          [`medium_c${id}`]: inputSettings.medium_c,
          [`duration_c${id}`]: inputSettings.duration_c,
          [`spec_c${id}`]: inputSettings.spec_c,
          [`medium_d${id}`]: inputSettings.medium_d,
          [`duration_d${id}`]: inputSettings.duration_d,
          [`spec_d${id}`]: inputSettings.spec_d,
          [`medium_e${id}`]: inputSettings.medium_e,
          [`duration_e${id}`]: inputSettings.duration_e,
          [`spec_e${id}`]: inputSettings.spec_e,
          [`medium_f${id}`]: inputSettings.medium_f,
          [`duration_f${id}`]: inputSettings.duration_f,
          [`spec_f${id}`]: inputSettings.spec_f,
        }
      }]
    };
    writeBlockSettings(payload);
  };

  const handleClickRefreshButton = () => {
    const payload = {
      messageId: `rdb${id}`,
      devices: `block${id}`,
      mode: 1
    };
    readBlockSettings(payload);
  };

  const handleInputChange = (event, param) => {
    let value = parseFloat(event.target.value);

    setInputSettings(settings => {
      return {
        ...settings,
        [param]: value
      };
    });
  };

  const handleClickArrow = (direction) => {
    const displayedSessionIndex = SESSION_IDS.indexOf(displayedSessionId);
    let nextSessionIndex;
    const isFirstSession = displayedSessionIndex === 0;
    const isLastSession = displayedSessionIndex === SESSION_IDS.length - 1;

    const firstSessionIndex = 0;
    const lastSessionIndex = SESSION_IDS.length - 1;

    if (direction === 'left') {
      if (isFirstSession) {
        nextSessionIndex = lastSessionIndex;
      } else {
        nextSessionIndex = displayedSessionIndex - 1;
      }
    } else {
      if (isLastSession) {
        nextSessionIndex = firstSessionIndex;
      } else {
        nextSessionIndex = displayedSessionIndex + 1;
      }
    }

    setDisplayedSessionId(SESSION_IDS[nextSessionIndex]);
  };

  return (
    <Card classes={{ root: classes.card }}>
      {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
      <CardContent>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            id="block-select"
            value={id}
            onChange={(event) => handleSelectBlock(event.target.value)}
            classes={{ root: classes.select }}
            disabled={isWriteSettingsInitiated || isReadSettingsInitiated}
          >
            {
              BLOCK_IDS.map(id => (
                <MenuItem
                  key={id}
                  value={id}
                  classes={{ root: classes.menuItem }}
                >
                  <h3 className={classes.heading}>
                    Block {id}
                  </h3>
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {!isDisplayAllSessions &&
          <Box display='flex' justifyContent='space-between'>
            <IconButton onClick={() => handleClickArrow('left')} disabled={isDisplayAllSessions}>
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={() => handleClickArrow('right')} disabled={isDisplayAllSessions}>
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          </Box>
        }
        <form noValidate autoComplete="off">
          <Collapse in={isDisplayAllSessions}>
            {SESSION_IDS.map(id =>
              <BlockSessionSetting
                key={id}
                id={id}
                medium={inputSettings[`medium_${id}`]}
                duration={inputSettings[`duration_${id}`]}
                spec={inputSettings[`spec_${id}`]}
                handleInputChange={handleInputChange}
              />
            )}
          </Collapse>
          <Collapse in={!isDisplayAllSessions}>
            <BlockSessionSetting
              id={displayedSessionId}
              medium={inputSettings[`medium_${displayedSessionId}`]}
              duration={inputSettings[`duration_${displayedSessionId}`]}
              spec={inputSettings[`spec_${displayedSessionId}`]}
              handleInputChange={handleInputChange}
            />
          </Collapse>
          <Box display='flex' justifyContent='center' alignItems='top' marginBottom='16px'>
            <Button
              variant="contained"
              disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
              onClick={() => setIsDisplayAllSessions(prev => !prev)}
            >
              {isDisplayAllSessions ?
                "SHOW SINGLE SESSION"
                :
                "SHOW ALL SESSIONS"
              }
            </Button>
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickSendButton}
              disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
              classes={{ root: classes.button }}
              startIcon={<SendIcon />}
            >
              SEND
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickRefreshButton}
              disabled={(isWriteSettingsInitiated || isReadSettingsInitiated) ? true : false}
              classes={{ root: classes.button }}
              startIcon={<RefreshIcon />}
            >
              REFRESH
            </Button>
          </Box>
        </form>
        <AlertSnackbar writeSettingsResult={writeSettingsResult} />
      </CardContent>
      {(isWriteSettingsInitiated || isReadSettingsInitiated) && <LinearProgress />}
    </Card >
  );
}

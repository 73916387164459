import React, { useState, useEffect } from 'react';
import { Modal, Grid, TextField, Button, CircularProgress, InputLabel, Select, MenuItem } from '@material-ui/core';

// Only editables are sphec splec sphph splph addcl schedule
export default function AddEditModal({ options, open, close, submit, data = {}, submitting }) {

    const [inputs, setInputs] = useState(default_inputs);
    const [errors, setErrors] = useState(default_errors);
    const [inputOptions, setInputOptions] = useState({
        'sphec': {
            'min': '',
            'max': ''
        },
        'splec': {
            'min': '',
            'max': ''
        },
        'sphph': {
            'min': '',
            'max': ''
        },
        'splph': {
            'min': '',
            'max': ''
        },
        'addcl': {
            'min': '',
            'max': ''
        },
    });
    const [disable, setDisable] = useState(submitting);

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setInputs(data);
            for (let i in data) {
                if (i == 'sphec' || i == 'splec' || i == 'sphph' || i == 'splph' || i == 'addcl') {
                    validateInput(i, data[i]);
                }
            }
        } else {
            setInputs(default_inputs);
        }
    }, [data]);

    // ENABLE/DISABLE SUBMIT BUTTON
    useEffect(() => {
        let count = 0;
        for (let i in inputs) {
            if ((i == 'sphec' || i == 'splec' || i == 'sphph' || i == 'splph') && inputs[i] == '') {
                count++;
            }
        }
        for (let i in errors) {
            if (errors[i] != '') {
                count++;
            }
        }

        if (count > 0) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    }, [inputs]);

    useEffect(() => {
        for (let i of options) {
            setInputOptions(prev => ({
                ...prev,
                [i.parameter]: {
                    'min': i.device_min ? i.device_min : i.default_min,
                    'max': i.device_max ? i.device_max : i.default_max,
                }
            }));
        }
    }, [options]);

    function handleChange(type, e) {
        setInputs(prev => ({
            ...prev,
            [type]: e.target.value
        }));
        validateInput(type, e.target.value);
    }

    function validateInput(type, e) {
        if (type == 'schedule') {
            if (e == '') {
                return setErrors(prev => ({
                    ...prev,
                    'schedule': 'Invalid Date'
                }));
            } else {
                return setErrors(prev => ({
                    ...prev,
                    [type]: ``
                }));
            }
        } else if (type == 'sphec' || type == 'splec' || type == 'sphph' || type == 'splph') {
            let min_value = parseFloat(inputOptions[type]['min']);
            let max_value = parseFloat(inputOptions[type]['max']);
            e = parseFloat(e);


            if (e <= min_value || e >= max_value) {
                return setErrors(prev => ({
                    ...prev,
                    [type]: `Minimum ${inputOptions[type]['min']}, Maximum ${inputOptions[type]['max']}`
                }));
            } else {
                return setErrors(prev => ({
                    ...prev,
                    [type]: ''
                }));
            }
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                disableBackdropClick
            >
                <div style={styles.modalContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                id="date"
                                label="Schedule"
                                type="date"
                                defaultValue={data.schedule ? data.schedule : formatDate(new Date())}
                                style={{ width: '100%' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleChange('schedule', e)}
                                error={errors.schedule.length > 0 ? true : false}
                                helperText={errors.schedule}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="EC Set Point (mS/cm)"
                                type="number"
                                fullWidth
                                defaultValue={data.sphec ? data.sphec : ''}
                                onChange={(e) => handleChange('sphec', e)}
                                error={errors.sphec.length > 0 ? true : false}
                                helperText={errors.sphec}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="delta EC Set Point (mS/cm)"
                                type="number"
                                fullWidth
                                defaultValue={data.splec ? data.splec : ''}
                                onChange={(e) => handleChange('splec', e)}
                                error={errors.splec.length > 0 ? true : false}
                                helperText={errors.splec}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="pH Set Point"
                                type="number"
                                fullWidth
                                defaultValue={data.sphph ? data.sphph : ''}
                                onChange={(e) => handleChange('sphph', e)}
                                error={errors.sphph.length > 0 ? true : false}
                                helperText={errors.sphph}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="delta pH Set Point"
                                type="number"
                                fullWidth
                                defaultValue={data.splph ? data.splph : ''}
                                onChange={(e) => handleChange('splph', e)}
                                error={errors.splph.length > 0 ? true : false}
                                helperText={errors.splph}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="addcl-label" required>Adding</InputLabel>
                            <Select
                                labelId="addcl-label"
                                id="addcl"
                                value={inputs.addcl}
                                onChange={e => handleChange('addcl', e)}
                                style={{ width: '100%' }}
                            >
                                <MenuItem key="acid" value="acid">Acid</MenuItem>
                                <MenuItem key="alkali" value="alkali">Alkali</MenuItem>
                            </Select>
                        </Grid>

                    </Grid>

                    <div style={styles.modalSubmitBtn}>
                        <Button variant="contained"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setInputs(default_inputs);
                                setErrors(default_errors);
                                return close();
                            }} >
                            Close
                        </Button>
                        <Button variant="contained" disabled={submitting ? submitting : disable} onClick={() => submit(inputs)}>
                            {submitting ? (
                                <CircularProgress />
                            ) : 'Submit'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

const styles = {
    modalContainer: {
        width: '80%',
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: '0 0 1px 0 rgb(0 0 0 / 31%), 0 4px 6px -2px rgb(0 0 0 / 25%)',
        padding: '16px 32px 24px',
        margin: 'auto',
        marginTop: '200px',
        borderRadius: '10px'
    },
    modalSubmitBtn: {
        textAlign: 'right'
    },
};

const default_errors = {
    'schedule': '',
    'sphec': '',
    'splec': '',
    'sphph': '',
    'splph': '',
    'addcl': '',
};
const default_inputs = {
    'sphec': '',
    'schedule': formatDate(new Date()),
    'sphec': '',
    'splec': '',
    'sphph': '',
    'splph': '',
    'addcl': 'acid',
};



function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
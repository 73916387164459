import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { Multiselect } from "multiselect-react-dropdown";
import category from "../../../services/node-category/category";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";
import { refetchApi } from '../../../shared/utils';

function AddCategory({ closeAddCategory }) {
	const [categoryList, setCategoryList] = useState([]);
	const [newCatName, setNewCatName] = useState("");
	const [oldCatName, setOldCatName] = useState("");
	const [nodeList, setNodeList] = useState([]);
	const [selectedNodeList, setSelectedNodeList] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);

	const [mainSelectedNodeList, setMainSelectedNodeList] = useState([]);
	const [mainDeselectedNodeList, setMainDeselectedNodeList] = useState([]);

	const [modalAddCat, setModalAddCat] = useState(false);
	const toggleAddCat = () => setModalAddCat(!modalAddCat);

	const [modalEditCat, setModalEditCat] = useState(false);
	const toggleEditCat = () => setModalEditCat(!modalEditCat);

	const [modalAddNode, setAddNode] = useState(false);
	const toggleAddNode = () => setAddNode(!modalAddNode);

	const [loading, setLoading] = useState(true);

	let newEntry = false;

	useEffect(() => {
		setLoading(true);
		category
			.getInstance()
			.getCategory()
			.then((result) => {
				setCategoryList(result);
				setLoading(false);
			});
	}, []);

	function onAddCategory(e) {
		e.preventDefault();

		let dataToSubmit = {
			category: [
				{
					username: localStorage.getItem("cloudatik"),
					category: newCatName,
				},
			],
		};
		setLoading(true);
		category
			.getInstance()
			.addCategory(dataToSubmit)
			.then((result) => {
				setCategoryList((categoryList) => [
					...categoryList,
					{ category: newCatName },
				]);
				alert(result["message"]);
				window.location.reload();
				setLoading(false);
			});
	}

	function onDeleteCategory(categoryName) {
		let dataToSubmit = {
			username: localStorage.getItem("cloudatik"),
			category: categoryName,
		};

		setLoading(true);
		category
			.getInstance()
			.deleteCategory(dataToSubmit)
			.then((result) => {
				alert(result["message"]);
				if (result["success"]) {
					window.location.reload();
				}
				setLoading(false);
			});
	}

	function onEditCategory(e) {
		e.preventDefault();

		let dataToSubmit = {
			username: localStorage.getItem("cloudatik"),
			selected: oldCatName,
			updated: newCatName,
		};

		setLoading(true);
		category
			.getInstance()
			.updateCategory(dataToSubmit)
			.then((result) => {
				alert(result["message"]);
				window.location.reload();
				setLoading(false);
			});
	}

	function onUpdateNode() {
		setLoading(true);

		for (let index = 0; index < mainSelectedNodeList.length; index++) {
			mainSelectedNodeList[index]["username"] =
				localStorage.getItem("cloudatik");
			mainSelectedNodeList[index]["sensor_set"] =
				mainSelectedNodeList[index]["node"].split("-")[1];
			mainSelectedNodeList[index]["category"] = selectedCategory;
		}
		for (let index = 0; index < mainDeselectedNodeList.length; index++) {
			mainDeselectedNodeList[index]["username"] =
				localStorage.getItem("cloudatik");
			mainDeselectedNodeList[index]["sensor_set"] =
				mainDeselectedNodeList[index]["node"].split("-")[1];
		}

		let dataToSubmit = {
			selected: mainSelectedNodeList,
			deselected: mainDeselectedNodeList,
		};

		category
			.getInstance()
			.updateCategoryNode(dataToSubmit)
			.then((result) => {
				alert(result["message"]);
				window.location.reload();
				setLoading(false);
			});
	}

	function onOpenEditModal(categoryName) {
		setModalEditCat(true);
		setOldCatName(categoryName);
	}

	function onOpenAddNodeModal(categoryName) {
		setLoading(true);
		setAddNode(true);
		setSelectedCategory(categoryName);
		fetch(
			`${process.env.REACT_APP_API_URL
			}/getUserNodeCategory/${localStorage.getItem("cloudatik")}/`, {
			headers: {
				'Authorization': localStorage.getItem('cloudatik-access')
			}
		}
		)
			.then((res) => res.json())
			.then(
				(result) => {
					if (result.code && result.code === 'token_not_valid') {
						refetchApi();
					}

					let nodeWithCategory = [];
					let selectedNode = [];
					let allNode = [];
					let allNodeWithCategory = [];
					setNodeList([]);
					setSelectedNodeList([]);
					for (let index = 0; index < result["group"].length; index++) {
						nodeWithCategory.push({
							node: `${result["group"][index]["serial"]}-${result["group"][index]["sensor_set"] == null
								? 1
								: result["group"][index]["sensor_set"]
								}`,
							category: result["group"][index]["category"],
						});

						if (result["group"][index]["category"] == categoryName) {
							selectedNode.push(
								`${result["group"][index]["serial"]}-${result["group"][index]["sensor_set"] == null
									? 1
									: result["group"][index]["sensor_set"]
								}`
							);
						}
					}

					for (let index = 0; index < result["node"].length; index++) {
						let node_id = `${result["node"][index]["serial"]}-${result["node"][index]["sensor_set"] == null
							? 1
							: result["node"][index]["sensor_set"]
							}`;
						allNode.push({
							node: node_id,
							device_id: result["node"][index]["device_id"],
						});
					}

					for (let x = 0; x < allNode.length; x++) {
						let a = 0;
						for (let index = 0; index < nodeWithCategory.length; index++) {
							if (nodeWithCategory[index]["node"] == allNode[x]["node"]) {
								allNodeWithCategory.push({
									id: x,
									node: nodeWithCategory[index]["node"],
									category: nodeWithCategory[index]["category"],
									device_id: allNode[x]["device_id"],
								});
								a = 1;
							}
						}
						if (a == 0) {
							allNodeWithCategory.push({
								id: x,
								node: allNode[x]["node"],
								category: null,
								device_id: allNode[x]["device_id"],
							});
						}
					}

					for (let index = 0; index < allNodeWithCategory.length; index++) {
						setNodeList((nodeList) => [
							...nodeList,
							allNodeWithCategory[index],
						]);

						if (allNodeWithCategory[index]["category"] == categoryName) {
							newEntry = true;
							setSelectedNodeList((selectedNodeList) => [
								...selectedNodeList,
								allNodeWithCategory[index],
							]);
						}
					}
					setLoading(false);
				},
				(error) => {
					console.log(error);
				}
			);
	}

	function onSelect(selectedList, selectedItem) {
		setMainSelectedNodeList(selectedList);
	}

	function onRemove(selectedList, removedItem) {
		setMainDeselectedNodeList((mainDeselectedNodeList) => [
			...mainDeselectedNodeList,
			removedItem,
		]);
	}

	return (
		<div>
			{loading && <LoadingSpinner />}
			<Modal isOpen={modalAddNode} toggle={toggleAddNode} className={"sm"}>
				<form>
					<ModalHeader toggle={toggleAddNode}>Edit Node</ModalHeader>
					<ModalBody>
						<div className="container">
							<Multiselect
								options={nodeList} // Options to display in the dropdown
								selectedValues={selectedNodeList} // Preselected value to persist in dropdown
								onSelect={onSelect} // Function will trigger on select event
								onRemove={onRemove} // Function will trigger on remove event
								displayValue="node" // Property name to display in the dropdown options
							/>
						</div>
					</ModalBody>
					<ModalFooter>
						<button
							type="button"
							className="btn btn-primary"
							onClick={onUpdateNode}
						>
							Save Node
						</button>
						<Button color="secondary" onClick={toggleAddNode}>
							Cancel
						</Button>
					</ModalFooter>
				</form>
			</Modal>

			<Modal isOpen={modalEditCat} toggle={toggleEditCat} className={"sm"}>
				<form onSubmit={onEditCategory}>
					<ModalHeader toggle={toggleEditCat}>Edit Category</ModalHeader>
					<ModalBody>
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="form-group">
										<label for="txt_category">Old Category Name</label>
										<input
											type="text"
											className="form-control"
											id="txt_category"
											value={oldCatName}
											onChange={(e) => setOldCatName(e.target.value)}
											required
										/>
										<small id="txt_category" className="form-text text-muted">
											Proper Category.
										</small>
									</div>
								</div>
								<div className="col">
									<div className="form-group">
										<label for="txt_new_category">New Category Name</label>
										<input
											type="text"
											className="form-control"
											id="txt_new_category"
											value={newCatName}
											onChange={(e) => setNewCatName(e.target.value)}
											required
										/>
										<small id="txt_category" className="form-text text-muted">
											Proper Category.
										</small>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="submit" className="btn btn-primary">
							Update Category
						</button>
						<Button color="secondary" onClick={toggleEditCat}>
							Cancel
						</Button>
					</ModalFooter>
				</form>
			</Modal>

			<Modal isOpen={modalAddCat} toggle={toggleAddCat} className={"sm"}>
				<form onSubmit={onAddCategory}>
					<ModalHeader toggle={toggleAddCat}>Add Category</ModalHeader>
					<ModalBody>
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="form-group">
										<label for="txt_category">Category Name</label>
										<input
											type="text"
											className="form-control"
											id="txt_category"
											value={newCatName}
											onChange={(e) => setNewCatName(e.target.value)}
											required
										/>
										<small id="txt_category" className="form-text text-muted">
											Proper Category.
										</small>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="submit" className="btn btn-primary">
							Add Category
						</button>
						<Button color="secondary" onClick={toggleAddCat}>
							Cancel
						</Button>
					</ModalFooter>
				</form>
			</Modal>

			<Modal isOpen={true} toggle={closeAddCategory} className={"sm"}>
				<ModalHeader toggle={closeAddCategory}>Category</ModalHeader>
				<ModalBody>
					<div className="container">
						<div className="row">
							<div className="col">
								<table>
									<tr>
										<th>Category</th>
										<th>Action</th>
									</tr>
									{categoryList.map((item) => (
										<tr>
											<td>{item.category}</td>
											<td>
												{
													<div className="row">
														<div className="col">
															<div
																onClick={() =>
																	onOpenAddNodeModal(item.category)
																}
															>
																<AddIcon />
															</div>
														</div>
														<div className="col">
															<div
																onClick={() => onOpenEditModal(item.category)}
															>
																<EditIcon />
															</div>
														</div>
														<div className="col">
															<div
																onClick={() => onDeleteCategory(item.category)}
															>
																<DeleteIcon />
															</div>
														</div>
													</div>
												}
											</td>
										</tr>
									))}
								</table>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => setModalAddCat(true)}
					>
						ADD
					</button>
					<Button color="secondary" onClick={closeAddCategory}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default AddCategory;

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    '&:last-child': {
      paddingBottom: 16
    }
  },
  heading: {
    fontSize: '1.2rem'
  },
  icon: {
    height: 24,
    width: 24
  },
  connected: {
    fill: green[500]
  },
  disconnected: {
    fill: red[500]
  }
}));


export default function StatusCard({
  isConnected,
}) {

  const classes = useStyles();

  return (
    <Card>
      <CardContent classes={{ root: classes.cardContent }}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          {
            isConnected
              ?
              <>
                <CheckCircleIcon classes={{ root: `${classes.icon} ${classes.connected}` }} />
                <h3 className={classes.heading}>
                  Connected
                </h3>
              </>
              :
              <>
                <ErrorIcon classes={{ root: `${classes.icon} ${classes.disconnected}` }} />
                <h3 className={classes.heading}>
                  Disconnected
                </h3>
              </>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import BarChart from "../../Components/Chart/BarChart";
import LineChart from "../../Components/Chart/LineChart";
import MultiLineChart from "../../Components/Chart/MultiLineChart";
import DigitalLcdStatus from "../../Components/LCD/DigitalLcdStatus";
import CardLcd from "../../Components/LCD/CardLcdStatus";

function NodeDigital({
    lineChart,
    barChart,
    multilineChart,
    lcdStatus,
    newData,
    minDate,
    newLcdData,
    lcdMessage,
    newSinglePropData,
    newMultiPropData
}) {
    multilineChart = multilineChart === undefined ? [] : multilineChart;
    lineChart = lineChart === undefined ? [] : lineChart;
    barChart = barChart === undefined ? [] : barChart;

    const [mDate, setMDate] = useState(minDate);
    const [nData, setNData] = useState(newData);

    const [mainLcdData, setMainLcdData] = useState(lcdStatus);

    useEffect(() => {
        setMDate(minDate);
    }, [minDate]);

    useEffect(() => {
        setNData(newData);
    }, [newData]);

    useEffect(() => {
        setMainLcdData(lcdStatus);
    }, [lcdStatus]);

    useEffect(() => {
        for (let i = 0; i < newLcdData.length; i++) {
            if (newLcdData[i]["chart_category"] == "msg") {
                if (newLcdData[i]["parameter"] == "msg") {
                    let msgId = parseInt(newData[0]["msg"]);
                    for (let x = 0; x < lcdMessage.length; x++) {
                        if (lcdMessage[x]["id"] == msgId) {
                            newLcdData[i]["value"] = lcdMessage[x]["message"];
                        }
                    }
                }
            }

            if (newLcdData[i]["chart_category"] == "onoff") {
                let parameter = parseInt(newData[0][newLcdData[i]["parameter"]]);
                if (parameter == 0) {
                    newLcdData[i]["value"] = "ON";
                } else {
                    newLcdData[i]["value"] = "OFF";
                }
            }

            if (newLcdData[i]["chart_category"] == "yesno") {
                let parameter = parseInt(newData[0][newLcdData[i]["parameter"]]);
                if (parameter == 0) {
                    newLcdData[i]["value"] = "NO";
                } else {
                    newLcdData[i]["value"] = "YES";
                }
            }

            if (newLcdData[i]["chart_category"] == "raindry") {
                let parameter = parseInt(newData[0][newLcdData[i]["parameter"]]);
                if (parameter == 0) {
                    newLcdData[i]["value"] = "DRY";
                } else {
                    newLcdData[i]["value"] = "RAIN";
                }
            }

            if (newLcdData[i]["chart_category"] == "stext") {
                newLcdData[i]["value"] = newData[0][newLcdData[i]["parameter"]];
            }
        }
        setMainLcdData(newLcdData);
    }, [newLcdData]);

    return (
        <>
            <div className="row">
                {mainLcdData.length != 0 && mainLcdData.map((item, index) => (
                    <div className="col-sm-12 col-md-6" key={`${item.label}-${item.parameter}`}>
                        {/*<DigitalLcdStatus index={index} isMsg={item.parameter == "msg" ? true : false} label={item.label} value={item.value}/>*/}
                        <CardLcd index={`${index}`} isMsg={item.parameter == "msg" ? true : false} label={item.label}
                            value={item.value} />
                    </div>
                ))}
                {mainLcdData.length == 0 && lcdStatus.map((item, index) => (
                    <div className="col-sm-12 col-md-6" key={`${item.label}-${item.parameter}`}>
                        {/*<DigitalLcdStatus index={index} isMsg={item.parameter == "msg" ? true : false} label={item.label} value={item.value}/>*/}
                        <CardLcd index={`${index}`} isMsg={item.parameter == "msg" ? true : false} label={item.label}
                            value={item.value} />
                    </div>
                ))}
            </div>
            <div className="row">
                {multilineChart.map((multi_line_chart, index) => (
                    <div className="col-sm-12 col-md-6" key={`${multi_line_chart.label}-${multi_line_chart.parameter}`}>
                        <MultiLineChart
                            index={index}
                            chart_title={multi_line_chart["chart_title"]}
                            plot_limit={multi_line_chart["plot_limit"]}
                            limit_low={multi_line_chart["limit_low"]}
                            limit_low_parameter={multi_line_chart["limit_low_parameter"]}
                            limit_high={multi_line_chart["limit_high"]}
                            limit_high_parameter={multi_line_chart["limit_high_parameter"]}
                            plot_limit={multi_line_chart["plot_limit"]}
                            data={multi_line_chart["data"]}
                            label={multi_line_chart["label"]}
                            device_id={multi_line_chart["device_id"]}
                            serial={multi_line_chart["serial"]}
                            unit={multi_line_chart["unit"]}
                            parameter={multi_line_chart["parameter"]}
                            sensor_list={multi_line_chart["sensor_list"]}
                            newData={nData}
                            minDate={mDate}
                            newMultiPropData={newMultiPropData}
                        />
                        <br />
                    </div>
                ))}
                {lineChart.map((line_chart, index) => (
                    <div className="col-sm-12 col-md-6" key={`${line_chart.label}-${line_chart.parameter}`}>
                        <LineChart
                            index={index}
                            plot_limit={line_chart["plot_limit"]}
                            limit_low={line_chart["limit_low"]}
                            limit_high={line_chart["limit_high"]}
                            plot_control={line_chart["plot_control"]}
                            control_max={line_chart["control_max"]}
                            control_min={line_chart["control_min"]}
                            data={line_chart["data"]}
                            label={line_chart["label"]}
                            device_id={line_chart["device_id"]}
                            parameter={line_chart["parameter"]}
                            unit={line_chart["unit"]}
                            newData={nData}
                            minDate={mDate}
                            limit_low_parameter={line_chart["limit_low_parameter"]}
                            limit_high_parameter={line_chart["limit_high_parameter"]}
                            newSinglePropData={newSinglePropData}
                        />
                        <br />
                    </div>
                ))}
                {barChart.map((bar_chart, index) => (
                    <div className="col-sm-12 col-md-6" key={`${bar_chart.label}-${bar_chart.parameter}`}>
                        <BarChart
                            index={index}
                            data={bar_chart["data"]}
                            label={bar_chart["label"]}
                            device_id={bar_chart["device_id"]}
                            parameter={bar_chart["parameter"]}
                            unit={bar_chart["unit"]}
                            newData={nData}
                            minDate={mDate}
                            newSinglePropData={newSinglePropData}
                        />
                        <br />
                    </div>
                ))}
            </div>
        </>
    );
}

export default NodeDigital;

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React, { useEffect, useState } from "react";
import { getMax, getMin } from "../../shared/DataStructure";
import { utcToMtyConvertionDate } from "../../shared/DateTimeConvertion";
import LcdDisplay from "../LCD";

am4core.useTheme(am4themes_animated);
const moment = require("moment-timezone");

let led;
let mainSeries = [];
let displaysingle = [];

const colorSeries = ["#63C5DA", "#0492c2", "#3944BC", "#48AAAD", "#2832C2"];

function MultiLineChart({
    data,
    index,
    sensor_list,
    label,
    minDate,
    unit,
    parameter,
    newData,
    plot_limit,
    limit_low,
    limit_high,
    limit_low_parameter,
    limit_high_parameter,
    newMultiPropData
}) {
    const [onChangeTitle, setOnChangeTitle] = useState(false);
    const [lcdParameterList, setLcdParameterList] = useState([]);
    const [selectedSensorSet, setSelectedSensorSet] = useState(1);
    const [chartL, setChartL] = useState();
    let dateAxis;
    let maxValue = 0;
    let minValue = 99;
    let minGraph = 999;
    let maxGraph = 0;
    let uuid = Math.floor(Math.random() * 99);
    let legenddata = [];

    function onChangeSensorSet(id) {
        setSelectedSensorSet(id);
    }


    const [isDisplaySetPointLines, setIsDisplaySetPointLines] = React.useState(true);

    useEffect(() => {
        // Append New Data
        try {
            let temp = [];
            // Add Chart Data
            let chartData = {};
            chartData["datetime"] = utcToMtyConvertionDate(newData[0]["datetime"]);

            // LCD Data
            for (let i = 0; i < sensor_list.length; i++) {
                // Chart Data
                chartData[`${parameter}-${sensor_list[i]}`] = newData[i][parameter];
                chartData["limit_low_parameter-" + (i + 1)] = newData[i][limit_low_parameter];
                chartData["limit_high_parameter-" + (i + 1)] = newData[i][limit_high_parameter];
                chartData["limit_high"] = newMultiPropData[0]["limit_high"];
                chartData["limit_low"] = newMultiPropData[0]["limit_low"];

                // LCD Data
                let js = {
                    type: "multi_line",
                    index: `${index}-${i}`,
                    unit: `${unit} [${sensor_list[i]}]`,
                    latestData: newData[i][parameter],
                    width: 175,
                };
                temp.push(js);
            }
            data.unshift(chartData);

            setLcdParameterList(temp);
        } catch (e) {
            console.log(e);
        }

        // Preprocess Data
        let finalData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i]["datetime"].valueOf() >= new Date(new Date(minDate).getFullYear(), new Date(minDate).getMonth(), new Date(minDate).getDate(), 0, 0, 0).valueOf()) {
                finalData.push(data[i]);
            }
        }

        // Find Array Min Max Value
        let maxArray = [];
        maxArray[0] = finalData[0][`limit_low_parameter-${selectedSensorSet}`] == undefined ? 0 : getMax(finalData, `limit_low_parameter-${selectedSensorSet}`);
        maxArray[1] = finalData[0][`limit_high_parameter-${selectedSensorSet}`] == undefined ? 0 : getMax(finalData, `limit_high_parameter-${selectedSensorSet}`);
        for (let i = 0; i < sensor_list.length; i++) {
            let temp = getMax(finalData, `${parameter}-${sensor_list[i]}`);
            maxArray.push(temp);
        }
        maxValue = maxArray.reduce(function (a, b) {
            return Math.max(a, b);
        });

        let minArray = [];
        minArray[0] = finalData[0][`limit_low_parameter-${selectedSensorSet}`] == undefined ? 99 : getMin(finalData, `limit_low_parameter-${selectedSensorSet}`);
        minArray[1] = finalData[0][`limit_high_parameter-${selectedSensorSet}`] == undefined ? 99 : getMin(finalData, `limit_high_parameter-${selectedSensorSet}`);
        for (let i = 0; i < sensor_list.length; i++) {
            let temp = getMin(finalData, `${parameter}-${sensor_list[i]}`);
            minArray.push(temp);
        }
        minValue = minArray.reduce(function (a, b) {
            return Math.min(a, b);
        });

        am4core.addLicense("ch-custom-attribution");
        let chart = am4core.create("chartdiv_multiline_" + index, am4charts.XYChart);

        // Config
        chart.properties.paddingLeft = 0;

        // Create Axis
        dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.min = new Date(minDate).getTime();
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 1,
        };

        // Main Series
        mainSeries = [];
        for (let idx = 0; idx < sensor_list.length; idx++) {
            createSeries(
                `${parameter}-${sensor_list[idx]}`,
                `${parameter}-${sensor_list[idx]}`,
                idx
            );
        }

        if (plot_limit == 1) {
            let range = valueAxis.axisRanges.create();
            range.value = limit_high;
            range.grid.stroke = am4core.color("red");
            range.grid.strokeDasharray = "1,1";
            range.grid.strokeWidth = 1;
            range.grid.strokeOpacity = 1;
            range.label.inside = true;
            range.label.fill = range.grid.stroke;
            range.label.verticalCenter = "bottom";

            let range2 = valueAxis.axisRanges.create();
            range2.value = limit_low;
            range2.grid.stroke = am4core.color("red");
            range2.grid.strokeDasharray = "1,1";
            range2.grid.strokeWidth = 1;
            range2.grid.strokeOpacity = 1;
            range2.label.inside = true;
            range2.label.fill = range2.grid.stroke;
            range2.label.verticalCenter = "bottom";

            if (parseFloat(maxValue) > parseFloat(limit_high)) {
                maxGraph = parseFloat(maxValue);
            } else {
                maxGraph = parseFloat(limit_high);
            }

            if (parseFloat(minValue) < parseFloat(limit_low)) {
                minGraph = parseFloat(minValue);
            } else {
                minGraph = parseFloat(limit_low);
            }
        } else {
            maxGraph = parseFloat(maxValue);
            minGraph = parseFloat(minValue);
        }

        if (data[0][`limit_low_parameter-${selectedSensorSet}`] !== undefined) {
            let series_limit_high_parameter = chart.series.push(new am4charts.LineSeries());
            series_limit_high_parameter.dataFields.valueY = `limit_high_parameter-${selectedSensorSet}`;
            series_limit_high_parameter.dataFields.dateX = "datetime";
            series_limit_high_parameter.strokeWidth = 1;
            series_limit_high_parameter.strokeDasharray = "1,1";
            //series_limit_high_parameter.stroke = series.stroke;
            series_limit_high_parameter.stroke = am4core.color("black");
            series_limit_high_parameter.name = "Set Point";

            let series_limit_low_parameter = chart.series.push(new am4charts.LineSeries());
            series_limit_low_parameter.dataFields.valueY = `limit_low_parameter-${selectedSensorSet}`;
            series_limit_low_parameter.dataFields.dateX = "datetime";
            series_limit_low_parameter.strokeWidth = 1;
            series_limit_low_parameter.strokeDasharray = "1,1";
            //series_limit_low_parameter.stroke = series.stroke;
            series_limit_low_parameter.stroke = am4core.color("green");
            series_limit_low_parameter.name = "Low Set Point";

            series_limit_high_parameter.tooltipText = "[bold]Date:[/] {datetime.formatDate()}\n[bold]Set Point:[/] {limit_high_parameter-1}\n" +
                "[bold]Low Set Point:[/] {limit_low_parameter-1}\n[bold]Alarm High:[/] {limit_high}\n[bold]Alarm Low:[/] {limit_low}";
            series_limit_high_parameter.tooltipContainer.fill = am4core.color("black");
        }

        valueAxis.min = minGraph;
        valueAxis.max = maxGraph;
        valueAxis.extraMin = 0.05;
        valueAxis.extraMax = 0.05;

        function createSeries(s, name, index) {
            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = s;
            series.dataFields.dateX = "datetime";
            series.strokeWidth = 1;
            series.minBulletDistance = 10;
            series.tooltip.pointerOrientation = "vertical";
            series.name = name;

            legenddata.push({
                name: name,
                fill: am4core.color(colorSeries[index])
            });

            return series;
        }

        chart.data = finalData;

        // Add Legend
        if (data[0]["limit_low_parameter"] !== undefined) {
            legenddata.push({
                name: "Set Point",
                fill: am4core.color("black")
            });
            legenddata.push({
                name: "Low Set Point",
                fill: am4core.color("green")
            });
            legenddata.push({
                name: "Alarm High/Low",
                fill: am4core.color("red")
            });
        } else {
            legenddata.push({
                name: "Alarm High/Low",
                fill: am4core.color("red")
            });
        }

        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";

        // Customize legend marker
        // https://www.amcharts.com/docs/v4/concepts/legend/
        const marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        const markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 12;
        markerTemplate.height = 12;


        chart.legend.labels.template.fontSize = 9;

        chart.legend.data = legenddata;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;

        setChartL(chart);

    }, [minDate, newData, selectedSensorSet]);

    useEffect(() => {
        let temp = [];
        for (let i = 0; i < sensor_list.length; i++) {
            let js = {
                type: "multi_line",
                index: `${index}-${i}`,
                unit: `${unit} [${sensor_list[i]}]`,
                latestData: data[i][`${parameter}-${sensor_list[i]}`],
                width: 175,
            };
            temp.push(js);
        }
        setLcdParameterList(temp);
    }, []);

    function onUpdateTitle(e) {
        e.preventDefault();
    }

    const handleToggleDisplaySetPointLines = () => {
        setIsDisplaySetPointLines(prevState => !prevState);
    };

    return (
        <div className="card">
            <div className="card-body" style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
                <div className="container">
                    {!onChangeTitle && (
                        <div onClick={() => setOnChangeTitle(true)}>
                            <h3 className="pb-1">{label}</h3>
                        </div>
                    )}
                    {onChangeTitle && (
                        <form onSubmit={onUpdateTitle}>
                            <div className="form-group">
                                <label>New Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id={"txt_" + label}
                                    placeholder="Enter New Title"
                                />
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={() => setOnChangeTitle(false)}
                                >
                                    Back
                                </button>
                                <button type="button" className="btn btn-primary" disabled={localStorage.getItem('demo_cloudatik')}>
                                    Change Title
                                </button>
                            </div>
                        </form>
                    )}
                    <div className="row justify-content-center align-items-center">
                        {lcdParameterList.map((item, index) => {

                            // customize width of LCD display box
                            // values below are gotten by trial and error
                            const valueText = `${(Math.round(item.latestData * 100) / 100).toFixed(2)}`;
                            const unitText = `${item.unit}`;

                            let customWidth = valueText.length * 20;
                            if (item.unit.includes("null")) {
                                customWidth += 10;
                            } else {
                                customWidth += (unitText.length * 6);
                            }

                            return (
                                <div className="col-xs-12 col-sm-4 d-flex justify-content-center align-items-center" key={`multiline_lcd_${index}`}>
                                    <LcdDisplay
                                        type={item.type}
                                        index={item.index}
                                        unit={item.unit}
                                        latestData={item.latestData}
                                        width={customWidth}
                                    />
                                </div>
                            );
                        }
                        )}
                    </div>
                    <div
                        style={{ width: "100%", minHeight: "300px", height: "100%" }}
                        id={"chartdiv_multiline_" + index}
                    ></div>
                    <div className="row pb-2">
                        <div className="col-xs-12 col-sm-4">
                            <button className="rounded-lg" onClick={() => onChangeSensorSet(1)}>Toggle Set Point Lines</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MultiLineChart;

import { Component } from "react";
import { utcToMtyConvertionDisplay } from "../../shared/DateTimeConvertion";
import { refetchApi } from '../../shared/utils';

export class nodeProfile extends Component {
	static myInstance = null;

	static getInstance() {
		return new nodeProfile();
	}

	async getNodeProfile(device_id) {
		let response = await fetch(
			`${process.env.REACT_APP_API_URL}/getNodeInfoV2/${device_id}/?format=json`,
			{
				headers: { 'Authorization': localStorage.getItem('cloudatik-access') },
			}
		);
		let responseJson = await response.json();
		if (responseJson.code && responseJson.code === 'token_not_valid') {
			refetchApi();
		}

		let dataToSubmit = {};

		return responseJson;
	}

	async updateNodeProfile(device_id, dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/saveNodeInfoV2/${device_id}/?format=json`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						'Authorization': localStorage.getItem('cloudatik-access')
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();

			return responseJson;
		} catch (error) {
			console.error(error);
		}
	}

	async deleteNode(dataToSubmit) {
		try {
			let response = await fetch(
				`${process.env.REACT_APP_API_URL}/deleteNodeV2`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						"Authorization": localStorage.getItem("cloudatik-access")
					},
					body: JSON.stringify(dataToSubmit),
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (error) {
			console.error(error);
		}
	}
}
export default nodeProfile;

import { Component } from "react";

export class auth extends Component {
    static myInstance = null;

    static getInstance() {
        return new auth();
    }

    async refreshToken() {
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}/token/refresh/?format=json`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ "refresh": localStorage.getItem('cloudatik-refresh') }),
                }
            );
            let responseJson = await response.json();
            return responseJson

        } catch (error) {
            console.error("token/refresh", error);
            // window.location.href = '/login'
        }
    }
}
export default auth;

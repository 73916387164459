import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import grey from '@material-ui/core/colors/grey';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 16,
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: '0 12px'
  },
  formControlLabel: {
    margin: 0,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  formControlMainSwitch: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

export default function PumpHouse({
  pumpHouseId,
  medium,
  spec,
  phpump,
  block,
  pump,
  start,
  handleInputChange
}) {

  const classes = useStyles();

  const [isDisplaySpec, setIsDisplaySpec] = useState(false);

  useEffect(() => {
    if (medium === 2) {
      setIsDisplaySpec(true);
    } else {
      setIsDisplaySpec(false);
    }
  }, [medium]);

  const ecInputProps = {
    min: 0,
    max: 9,
    step: 0.01
  };

  const handleSwitch = (event, param) => {
    event.target.value = event.target.checked ? 1 : 0;
    handleInputChange(event, param);
  };

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={4}
      padding={1}
      marginBottom={2}
    >
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">TYPE</FormLabel>
        <RadioGroup
          value={medium}
          onChange={(event) => handleInputChange(event, `medium${pumpHouseId}`)}
        >
          <FormControlLabel value={1} control={<Radio color="primary" />} label="WATER" />
          <FormControlLabel value={2} control={<Radio color="primary" />} label="FERTILIZER" />
        </RadioGroup>
      </FormControl>
      <Collapse in={isDisplaySpec}>
        <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
          <FormLabel component="label">EC</FormLabel>
          <Box display='flex' alignItems='center'>
            <TextField
              id="outlined-basic"
              label="ms/cm"
              variant="outlined"
              type="number"
              size='small'
              inputProps={ecInputProps}
              value={spec}
              onChange={(event) => handleInputChange(event, `spec${pumpHouseId}`)}
            />
          </Box>
        </FormControl>
      </Collapse>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">pH PUMP</FormLabel>
        <FormControlLabel
          control={
            <Switch
              checked={phpump === 1 ? true : false}
              onChange={(event) => handleSwitch(event, `phpump${pumpHouseId}`)}
              name={`phpump${pumpHouseId}_switch`}
              color="primary"
            />
          }
          label={phpump === 1 ? 'ON' : 'OFF'}
          classes={{ root: classes.formControlLabel }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">IRRIGATION PUMP</FormLabel>
        <FormControlLabel
          control={
            <Switch
              checked={pump === 1 ? true : false}
              onChange={(event) => handleSwitch(event, `pump${pumpHouseId}`)}
              name={`pump${pumpHouseId}_switch`}
              color="primary"
            />
          }
          label={pump === 1 ? 'ON' : 'OFF'}
          classes={{ root: classes.formControlLabel }}
        />
      </FormControl>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControl }}>
        <FormLabel component="legend">BLOCK</FormLabel>
        <RadioGroup
          value={block}
          onChange={(event) => handleInputChange(event, `block${pumpHouseId}`)}
        >
          {
            pumpHouseId === '1' ?
              <>
                <FormControlLabel value={1} control={<Radio color="primary" />} label="1" />
                <FormControlLabel value={2} control={<Radio color="primary" />} label="2" />
              </>
              :
              <>
                <FormControlLabel value={3} control={<Radio color="primary" />} label="3" />
                <FormControlLabel value={4} control={<Radio color="primary" />} label="4" />
                <FormControlLabel value={5} control={<Radio color="primary" />} label="5" />
              </>
          }
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" fullWidth classes={{ root: classes.formControlMainSwitch }}>
        <FormLabel component="legend">MAIN SWITCH</FormLabel>
        <FormControlLabel
          control={
            <Switch
              checked={start === 1 ? true : false}
              onChange={(event) => handleSwitch(event, `start${pumpHouseId}`)}
              name={`start${pumpHouseId}_switch`}
              color="primary"
            />
          }
          label={start === 1 ? 'ON' : 'OFF'}
          classes={{ root: classes.formControlLabel }}
        />
      </FormControl>
    </Box>
  );
};